import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {FormInput} from "../component/form/FormInput";
import {Button, Spinner} from "react-bootstrap";
import {UserRegistration} from "../model/user/UserRegistration";
import {UserService} from "../service/entity/UserService";
import {FormService} from "../service/FormService";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";
import {Link} from "react-router-dom";

export interface UserRegistrationFormProps {
    onSubmit?: (email: string) => void;
}

export function UserRegistrationForm(props: UserRegistrationFormProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const initialValues: UserRegistration = {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    function handleSubmit(userRegistration: UserRegistration, formikHelpers: FormikHelpers<UserRegistration>) {
        const userService = new UserService();

        userService.register(userRegistration)
            .then(() => {
                formikHelpers.setSubmitting(false);
                if (props.onSubmit) {
                    props.onSubmit(userRegistration.email);
                }
            })
            .catch((error: Error) => {
                const formService = new FormService<UserRegistration>();
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    userRegistration,
                    false);
            });
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                name: Yup.string().required('Required'),
                email: Yup.string().required('Required'),
                password: Yup.string().required('Required')
                    .min(6, 'Must be at least 6 characters long'),
                confirmPassword: Yup.string()
                    .required('Required')
                    .min(6, 'Must be at least 6 characters long')
                    .oneOf([Yup.ref('password')], 'Passwords don\'t match')
            })}
            onSubmit={handleSubmit}>
            {formik => (
                <Form noValidate={true}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Name *" name="name"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput
                                label="Email Address *"
                                name="email"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Password *" name="password" type="password"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Confirm Password *" name="confirmPassword" type="password"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                Register
                                {
                                    formik.isSubmitting &&
                                    <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                                }
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <small><em>By clicking register, you agree to abide by the <Link to="/terms" target="_blank">Terms and
                                Conditions</Link></em></small>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}