import {FetchEntityService} from "./FetchEntityService";
import {Company} from "../../model/company/Company";
import {CompanySummary} from "../../model/company/CompanySummary";
import {default as React} from "react";
import {Link} from "react-router-dom";
import {NumberService} from "../NumberService";

export class CompanyService extends FetchEntityService<Company> {
    constructor() {
        super('company');
    }

    async getByAlias(alias: string): Promise<Company> {
        const url = this.baseURL + '/alias/' + alias;
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    renderCompanySummaryLink(companySummary: CompanySummary) {
        return <div key={companySummary.id}>
            <Link to={'/company/' + companySummary.alias}>{companySummary.name}</Link>
            {
                (Number(companySummary.matchReportPercent) >= .5) &&
                <>&nbsp;<span className="badge rounded-pill">M</span></>
            }
            {
                (Number(companySummary.challengeReportPercent) >= .5) &&
                <>&nbsp;<span className="badge rounded-pill">C</span></>
            }
        </div>
    }

    renderCompanySummaryLinkArray(companySummaryArray: CompanySummary[]) {
        const reactNodes: React.ReactNode[] = [];
        for (let companySummary of companySummaryArray) {
            reactNodes.push(this.renderCompanySummaryLink(companySummary));
        }
        return reactNodes;
    }

    renderFirstLetterBlocks(startFirstLetter: string, endFirstLetter: string, firstLetterToCompanySummaryArrayMap: Map<string, CompanySummary[]>) {
        const startCharCode = startFirstLetter.charCodeAt(0);
        const endCharCode = endFirstLetter.charCodeAt(0);
        const reactNodes: React.ReactNode[] = [];

        for (let charCode = startCharCode; charCode <= endCharCode; charCode++) {
            const firstLetter = String.fromCharCode(charCode);
            const companySummaryArray = firstLetterToCompanySummaryArrayMap.get(firstLetter);
            if (companySummaryArray) {
                const companyReactNodes: React.ReactNode[] = [];
                for (let companySummary of companySummaryArray) {
                    companyReactNodes.push(this.renderCompanySummaryLink(companySummary));
                }
                reactNodes.push(<div className="mt-3" key={firstLetter}>
                    <h4>{firstLetter}</h4>
                    {companyReactNodes}
                </div>);
            }
        }
        return reactNodes;
    }

    renderCompanySummaryTableRows(companySummaryArray: CompanySummary[]) {
        const reactNodes: React.ReactNode[] = [];
        for (let companySummary of companySummaryArray) {
            reactNodes.push(<tr key={companySummary.id}>
                <td><Link to={'/company/' + companySummary.alias}>{companySummary.name}</Link></td>
                <td>{NumberService.formatNumberAsPercentageString(Number(companySummary.matchReportPercent))}</td>
            </tr>);
        }
        return reactNodes;
    }
}