import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {useHistory} from "react-router";
import {ChangePasswordForm} from "../../businesscomponent/ChangePasswordForm";
import {useContext, useEffect} from "react";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

export function ChangePasswordPage() {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const history = useHistory();

    useEffect(() => {
        document.title = 'Change Password | StatusMatcher';
    });

    function onSubmit() {
        history.push('/');
        setCommunicatorState(new CommunicatorState(
            'Your password has been changed',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
    }

    return (
        <div>
            <h3>Change Password</h3>
            <hr/>
            <ChangePasswordForm
                onSubmit={onSubmit}
            />
        </div>
    );
}