import {CacheStateService} from "./CacheStateService";
import {PagedModel} from "../../../model/PagedModel";
import {ReportSummary} from "../../../model/report/ReportSummary";
import {ReportService} from "../../entity/ReportService";
import {PaginationService} from "../../PaginationService";
import {ProgramReportListState} from "../../../state/cache/ProgramReportListState";

interface ProgramReportListStateRefreshData {
    programId: number;
    to: boolean;
    page: number;
}

export class ProgramReportListStateService extends CacheStateService<ProgramReportListState, ProgramReportListStateRefreshData> {
    private static readonly View = 'programReportList';

    protected isRefreshNeededStepTwo(refreshData: ProgramReportListStateRefreshData, programReportListState: ProgramReportListState) {
        return (
            refreshData.programId !== programReportListState.programId ||
            refreshData.to !== programReportListState.to ||
            refreshData.page !== programReportListState.pageMetadata.number
        );
    }

    protected async getNewStatePromise(refreshData: ProgramReportListStateRefreshData, programReportListState: ProgramReportListState): Promise<ProgramReportListState> {
        const reportService = new ReportService();

        const reportSummaryPagedModel: PagedModel<ReportSummary> = await reportService.getPageViewsByProgram<ReportSummary>(
            refreshData.page,
            PaginationService.DefaultPageSize,
            ProgramReportListStateService.View,
            refreshData.programId,
            refreshData.to);

        // Handle case of no data returned
        const reportSummaries: ReportSummary[] = reportSummaryPagedModel.collection
            ? reportSummaryPagedModel.collection
            : [];

        return new ProgramReportListState(refreshData.programId, refreshData.to, reportSummaries, reportSummaryPagedModel._metadata, false, true);
    }
}