import {CacheStateService} from "./CacheStateService";
import {ReportFullState} from "../../../state/cache/ReportFullState";
import {ReportFull} from "../../../model/report/ReportFull";
import {ReportService} from "../../entity/ReportService";

interface ReportFullStateRefreshData {
    id: number;
}

export class ReportFullStateService extends CacheStateService<ReportFullState, ReportFullStateRefreshData> {
    protected isRefreshNeededStepTwo(refreshData: ReportFullStateRefreshData, reportFullState: ReportFullState) {
        // If the requested report does not match the report in the current state, need a refresh
        return (refreshData.id > 0 &&
            (!reportFullState.reportFull || !reportFullState.reportFull.id || reportFullState.reportFull.id !== refreshData.id));
    }

    protected async getNewStatePromise(refreshData: ReportFullStateRefreshData, reportFullState: ReportFullState): Promise<ReportFullState> {
        if (refreshData.id) {
            const reportFull = await (new ReportService()).getOneView<ReportFull>(refreshData.id, 'full');

            return new ReportFullState(reportFull, false, true);
        } else {
            return new ReportFullState(null, false, false);
        }
    }
}