import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {useHistory, useParams} from "react-router";
import {useContext, useEffect, useState} from "react";
import {UserService} from "../../service/entity/UserService";
import {UserRegistrationToken} from "../../model/user/UserRegistrationToken";
import {UserFull} from "../../model/user/UserFull";
import {UserFullState} from "../../state/cache/UserFullState";
import {FormService} from "../../service/FormService";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

export interface UserRegistrationTokenPageProps {
    setCurrentUserState: (currentUserState: UserFullState) => void;
}

interface UserRegistrationTokenPageRouteParams {
    token: string;
}

export function UserRegistrationTokenPage(props: UserRegistrationTokenPageProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const history = useHistory();
    const userRegistrationTokenPageRouteParams = useParams<UserRegistrationTokenPageRouteParams>();
    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        document.title = 'Register | StatusMatcher';

        if (!requestSent) {
            const userService = new UserService();
            const userRegistrationToken: UserRegistrationToken = {
                token: userRegistrationTokenPageRouteParams.token
            };

            userService.registerWithToken(userRegistrationToken)
                .then((userFull: UserFull) => {
                    history.push('/');
                    props.setCurrentUserState(new UserFullState(userFull, false, true));
                    setCommunicatorState(new CommunicatorState(
                        'Your email address has been confirmed and user account activated',
                        'success',
                        true,
                        true));
                    ScrollService.scrollToTop();
                })
                .catch((error: Error) => {
                    const formService = new FormService<UserRegistrationToken>();
                    history.push('/');
                    formService.handleSubmitError(error,
                        setCommunicatorState,
                        undefined,
                        userRegistrationToken,
                        false,
                        true);
                    ScrollService.scrollToTop();
                });

            setRequestSent(true);
        }
    }, [requestSent, userRegistrationTokenPageRouteParams.token, history, props, setCommunicatorState]);

    return null;
}