import {CommunicatorState} from "../state/ui/CommunicatorState";
import {EntityTable} from "../component/EntityTable";
import {EntitySchema} from "../component/schema/EntitySchema";
import {default as React, useContext, useEffect, useState} from "react";
import {EntityListState} from "../state/cache/EntityListState";
import {EntityListStateService} from "../service/state/cache/EntityListStateService";
import {PaginationService} from "../service/PaginationService";
import {useHistory, useLocation} from "react-router";
import {BaseEntity} from "../model/BaseEntity";
import {ScrollService} from "../service/ScrollService";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface EntityListPageProps {
    entitySchema: EntitySchema;
    entityView: string;
    onChange?: () => void;
    highlightUnread: boolean;
    displayedActions: string[];
}

export function EntityListPage<T extends BaseEntity>(props: EntityListPageProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const pluralNameCapitalized = props.entitySchema.pluralName.substr(0, 1).toUpperCase() +
        props.entitySchema.pluralName.substr(1);

    const location = useLocation();
    const history = useHistory();
    const [entityListState, setEntityListState] = useState(new EntityListState<T>());
    const page = entityListState.pageMetadata ? entityListState.pageMetadata.number : 0;

    // Page title
    useEffect(() => {
        document.title = pluralNameCapitalized + ' | StatusMatcher';
    });

    // Entity list state
    useEffect(() => {
        const entityListStateService = new EntityListStateService<T>();
        entityListStateService.refresh({
                entityName: props.entitySchema.name,
                entityView: props.entityView,
                page: PaginationService.getPageFromLocation(location),
                pageSize: PaginationService.DefaultPageSize
            },
            entityListState,
            setEntityListState)
            .catch(() => {
                setCommunicatorState(new CommunicatorState('An error occurred while loading the page', 'danger', true));
            });
    }, [props, props.entitySchema.name, location, entityListState, setCommunicatorState]);

    function onPublish(baseEntity: BaseEntity) {
        // Optimization: instead of doing a full refresh of data from the database,
        // just update front end state to reflect published state of entity
        const entities: T[] = [...entityListState.entities];
        const existingEntity = entities.find(e => e.id === baseEntity.id);
        if (existingEntity) {
            (existingEntity['published' as keyof T] as unknown) = true;
            setEntityListState({...entityListState, entities});
            if (props.onChange) {
                props.onChange();
            }
        }
    }

    function onDelete(baseEntity: BaseEntity) {
        // Update front end state
        const entities: T[] = [...entityListState.entities];
        const deletedEntityIndex = entities.findIndex(e => e.id === baseEntity.id);
        if (deletedEntityIndex >= 0) {
            entities.splice(deletedEntityIndex, 1);
            setEntityListState({...entityListState, entities});
            if (props.onChange) {
                props.onChange();
            }
        }
    }

    if (!props.entitySchema || (props.entitySchema.name !== entityListState.entityName)) {
        // Schema does not match current data - should be loading new data
        // Do not attempt to render with this mismatch
        return null;
    }

    return <div>
        <h3 id="entity-table">{pluralNameCapitalized}</h3>
        <hr/>
        <EntityTable<T>
            entitySchema={props.entitySchema}
            entities={entityListState.entities}
            highlightUnread={props.highlightUnread}
            onPublish={onPublish}
            onDelete={onDelete}
            pageMetadata={entityListState.pageMetadata}
            onPreviousPage={() => {
                PaginationService.handlePreviousPage(location, history, page);
                ScrollService.scrollToElement('entity-table');
            }}
            onNextPage={() => {
                PaginationService.handleNextPage(location, history, page);
                ScrollService.scrollToElement('entity-table');
            }}
            displayedActions={props.displayedActions}
        />
    </div>;
}

EntityListPage.defaultProps = {
    entityView: '',
    highlightUnread: false,
    displayedActions: []
};