import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {useHistory} from "react-router";
import {ResetPasswordForm} from "../../businesscomponent/ResetPasswordForm";
import {useContext, useEffect} from "react";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

export function ResetPasswordPage() {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const history = useHistory();

    useEffect(() => {
        document.title = 'Reset Password | StatusMatcher';
    });

    function onSubmit(email: string) {
        history.push('/');
        setCommunicatorState(new CommunicatorState(
            'An email has been sent to ' + email + ' with instructions for resetting your password',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
    }

    return (
        <div>
            <h3>Reset Password</h3>
            <hr/>
            <ResetPasswordForm onSubmit={onSubmit}/>
        </div>
    );
}