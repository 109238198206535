import {CacheStateService} from "./CacheStateService";
import {PagedModel} from "../../../model/PagedModel";
import {PaginationService} from "../../PaginationService";
import {EntityListState} from "../../../state/cache/EntityListState";
import {FetchEntityService} from "../../entity/FetchEntityService";

interface EntityListStateRefreshData {
    entityName: string;
    entityView: string;
    page: number;
    pageSize?: number;
}

export class EntityListStateService<T> extends CacheStateService<EntityListState<T>, EntityListStateRefreshData> {
    protected isRefreshNeededStepTwo(refreshData: EntityListStateRefreshData, entityState: EntityListState<T>) {
        return (
            refreshData.entityName !== entityState.entityName ||
            refreshData.entityView !== entityState.entityView ||
            refreshData.page !== entityState.pageMetadata.number
        );
    }

    protected async getNewStatePromise(refreshData: EntityListStateRefreshData, entityState: EntityListState<T>): Promise<EntityListState<T>> {
        const fetchEntityService = new FetchEntityService<T>(refreshData.entityName);
        const pageSize = refreshData.pageSize ? refreshData.pageSize : PaginationService.DefaultPageSize;

        const entityPagedModel: PagedModel<T> = refreshData.entityView ?
            await fetchEntityService.getPageViews<T>(refreshData.page, pageSize, refreshData.entityView) :
            await fetchEntityService.getPage(refreshData.page, pageSize);

        // Handle case of no data returned
        const entities: T[] = (entityPagedModel.collection) ? entityPagedModel.collection : [];

        return new EntityListState<T>(refreshData.entityName,
            refreshData.entityView,
            entityPagedModel._metadata,
            entities,
            false,
            true);
    }
}