export class NumberService {
    static formatFractionAsPercentageString(num: number, denom: number): string {
        if (denom === 0) {
            return '0%';
        } else {
            return ((num / denom) * 100).toFixed(0) + '%';
        }
    }

    static formatNumberAsPercentageString(num: number): string {
        return (num * 100).toFixed(0) + '%';
    }
}