import {FetchEntityService} from "./FetchEntityService";
import {Vote} from "../../model/Vote";
import {HttpStatus} from "../error/HttpStatus";
import {ScrollService} from "../ScrollService";
import {FormService} from "../FormService";
import {ReportVoteState} from "../../state/cache/ReportVoteState";
import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {ReportService} from "./ReportService";
import {ReportFullState} from "../../state/cache/ReportFullState";

export class VoteService extends FetchEntityService<Vote> {
    constructor() {
        super('vote');
    }

    async getVoteByReport(reportId: number): Promise<Vote | null> {
        const url = this.baseURL
            + '?reportId=' + reportId.toString();
        const response = await fetch(url);

        if (response.status === HttpStatus.NO_CONTENT) {
            return Promise.resolve(null);
        } else {
            await this.fetchErrorService.checkResponseForError(response);
            return await response.json();
        }
    }

    onUserReportVote(up: boolean,
                     reportVoteState: ReportVoteState,
                     setReportVoteState: (reportVoteState: ReportVoteState) => void,
                     setCommunicatorState: (communicatorState: CommunicatorState) => void,
                     reportId: number,
                     reportFullState: ReportFullState,
                     setReportFullState: (reportFullState: ReportFullState) => void) {
        const formService = new FormService<{}>();
        const reportService = new ReportService();

        // If current vote matches up/down - delete that vote
        if (reportVoteState.vote && reportVoteState.vote.id && reportVoteState.vote.up === up) {
            this.deleteEntity(reportVoteState.vote.id)
                .catch((error: Error) => {
                    formService.handleSubmitError(error, setCommunicatorState, undefined, {}, false);
                    ScrollService.scrollToTop();
                });

            // Optimistic front end update #1 - update report vote state immediately
            setReportVoteState({...reportVoteState, vote: null});

            // Optimistic update #2 - update vote count on report immediately
            reportService.updateVoteCount(reportFullState, setReportFullState, up, -1);
        } else {
            // Otherwise - submit a new up vote
            if (reportVoteState.vote) {
                // Existing vote will be deleted - update front end report count immediately
                reportFullState = reportService.updateVoteCount(reportFullState, setReportFullState, reportVoteState.vote.up, -1);
            }

            // Manage userReportVoteState - consider as not loaded + pending request
            setReportVoteState({
                ...reportVoteState,
                vote: null,
                pendingRequest: true,
                loaded: false
            });

            const vote = new Vote(reportId, up);
            this.newEntity(vote)
                .then((voteReturned: Vote) => {
                    setReportVoteState({
                        ...reportVoteState,
                        vote: voteReturned,
                        pendingRequest: false,
                        loaded: true
                    });

                    // Front end report update without back end refresh - update vote count on report
                    reportService.updateVoteCount(reportFullState, setReportFullState, up, 1);
                })
                .catch((error: Error) => {
                    setReportVoteState({...reportVoteState, pendingRequest: false});
                    formService.handleSubmitError(error, setCommunicatorState, undefined, {}, false);
                    ScrollService.scrollToTop();
                });
        }
    }
}