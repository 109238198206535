import {CacheStateService} from "./CacheStateService";
import {Company} from "../../../model/company/Company";
import {EntityState} from "../../../state/cache/EntityState";
import {CompanyService} from "../../entity/CompanyService";

interface CompanyStateRefreshData {
    alias: string;
}

export class CompanyStateService extends CacheStateService<EntityState<Company>, CompanyStateRefreshData> {
    protected isRefreshNeededStepTwo(refreshData: CompanyStateRefreshData, companyState: EntityState<Company>) {
        return (!companyState.entity || companyState.entity.alias !== refreshData.alias);
    }

    protected async getNewStatePromise(refreshData: CompanyStateRefreshData, companyState: EntityState<Company>): Promise<EntityState<Company>> {
        if (refreshData.alias) {
            const company = await (new CompanyService()).getByAlias(refreshData.alias);

            return new EntityState<Company>(undefined, company, false, true);
        } else {
            return new EntityState<Company>(undefined, null, false, false);
        }
    }
}