import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {default as React, SyntheticEvent} from "react";
import {Vote} from "../model/Vote";

export interface VotingProps {
    voteUpCount: number;
    voteDownCount: number;
    onVoteUp: (e: SyntheticEvent) => void;
    onVoteDown: (e: SyntheticEvent) => void;
    currentVote: Vote | null | undefined;
    active: boolean;
}

export function Voting(props: VotingProps) {
    return <span>
        <small>
            Rating: {props.voteUpCount}&nbsp;
            {props.active ?
                <a href="#vote" onClick={props.onVoteUp}>
                    {props.currentVote && props.currentVote.up ?
                        <FontAwesomeIcon icon={['fas', 'thumbs-up']}/> :
                        <FontAwesomeIcon icon={['far', 'thumbs-up']}/>
                    }
                </a> :
                <FontAwesomeIcon icon={['far', 'thumbs-up']}/>
            }&nbsp;
            | {props.voteDownCount}&nbsp;
            {props.active ?
                <a href="#vote" onClick={props.onVoteDown}>
                    {props.currentVote && !props.currentVote.up ?
                        <FontAwesomeIcon icon={['fas', 'thumbs-down']}/> :
                        <FontAwesomeIcon icon={['far', 'thumbs-down']}/>
                    }
                </a> :
                <FontAwesomeIcon icon={['far', 'thumbs-down']}/>
            }
        </small>
    </span>
}