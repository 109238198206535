import {CacheStateService} from "./CacheStateService";
import {UserService} from "../../entity/UserService";
import {UserFullState} from "../../../state/cache/UserFullState";
import {UserFull} from "../../../model/user/UserFull";

interface UserFullStateRefreshData {
    id: number;
}

export class UserFullStateService extends CacheStateService<UserFullState, UserFullStateRefreshData> {
    protected isRefreshNeededStepTwo(refreshData: UserFullStateRefreshData, userFullState: UserFullState) {
        // If the requested user does not match the user in the current state, need a refresh
        return (refreshData.id > 0 &&
            (!userFullState.userFull || !userFullState.userFull.id || userFullState.userFull.id !== refreshData.id));
    }

    protected async getNewStatePromise(refreshData: UserFullStateRefreshData, userFullState: UserFullState): Promise<UserFullState> {
        if (refreshData.id) {
            const userFull = await (new UserService()).getOneView<UserFull>(refreshData.id, 'full');

            return new UserFullState(userFull, false, true);
        } else {
            return new UserFullState(null, false, false);
        }
    }
}