import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {FormInput} from "../component/form/FormInput";
import {Button, Spinner} from "react-bootstrap";
import {ChangePassword} from "../model/user/ChangePassword";
import {UserService} from "../service/entity/UserService";
import {FormService} from "../service/FormService";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface ChangePasswordFormProps {
    onSubmit?: () => void;
}

export function ChangePasswordForm(props: ChangePasswordFormProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const initialValues: ChangePassword = {
        currentPassword: '',
        password: '',
        confirmPassword: ''
    };

    function handleSubmit(changePassword: ChangePassword, formikHelpers: FormikHelpers<ChangePassword>) {
        const userService = new UserService();
        userService.changePassword(changePassword)
            .then(() => {
                formikHelpers.setSubmitting(false);
                if (props.onSubmit) {
                    props.onSubmit();
                }
            })
            .catch((error: Error) => {
                const formService = new FormService<ChangePassword>();
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    changePassword,
                    false);
            });
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                currentPassword: Yup.string().required('Required'),
                password: Yup.string().required('Required')
                    .min(6, 'Must be at least 6 characters long'),
                confirmPassword: Yup.string()
                    .required('Required')
                    .min(6, 'Must be at least 6 characters long')
                    .oneOf([Yup.ref('password')], 'Passwords don\'t match')
            })}
            onSubmit={handleSubmit}>
            {formik => (
                <Form noValidate={true}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput
                                label="Current Password *"
                                name="currentPassword"
                                type="password"
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="New Password *" name="password" type="password"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Confirm Password *" name="confirmPassword" type="password"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                Save
                                {
                                    formik.isSubmitting &&
                                    <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                                }
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}