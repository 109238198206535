import {Result} from "./Result";
import {BaseEntity} from "../BaseEntity";
import {EntitySchema} from "../../component/schema/EntitySchema";
import {FieldDefinition} from "../../component/schema/FieldDefinition";
import {FieldType} from "../../component/schema/FieldType";
import {EntityMetadata} from "../EntityMetadata";

export class ReportSummary implements BaseEntity {
    id: number | null = null;
    _metadata: EntityMetadata = new EntityMetadata();
    instructions: string = '';
    result: Result = Result.MATCH;
    published: boolean = false;
    read: boolean = false;
    fromProgram: string | null = null;
    fromStatus: string | null = null;
    toProgram: string | null = null;
    toStatus: string | null = null;
    userId: number | null = null;
    user: string | null = null;
    voteUpCount: number = 0;
    voteDownCount: number = 0;
    commentCount: number = 0;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    static readonly AdminSchema: EntitySchema = new EntitySchema(
        'report',
        'reports',
        [
            new FieldDefinition('id', 'ID', FieldType.Link),
            new FieldDefinition('fromProgram', 'From', FieldType.String),
            new FieldDefinition('toProgram', 'To', FieldType.String),
            new FieldDefinition('instructions', 'Instructions', FieldType.String, 40),
            new FieldDefinition('result', 'Result', FieldType.String),
            new FieldDefinition('user', 'User', FieldType.String),
            new FieldDefinition('createdAt', 'Created', FieldType.Date),
        ]
    );
}