import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {useContext, useEffect} from "react";
import {UserFullState} from "../../state/cache/UserFullState";
import {ScrollService} from "../../service/ScrollService";
import {useHistory} from "react-router";
import {Form, Formik, FormikHelpers} from "formik";
import {FormInput} from "../../component/form/FormInput";
import {FormTextarea} from "../../component/form/FormTextarea";
import {Button, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Message} from "../../model/message/Message";
import {FetchEntityService} from "../../service/entity/FetchEntityService";
import {FormService} from "../../service/FormService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

export interface MessageCreatePageProps {
    currentUserState: UserFullState;
}

interface MessageFormValues {
    name: string;
    email: string;
    subject: string;
    message: string;
}

export function MessageCreatePage(props: MessageCreatePageProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const MaxMessageSize = 10000;
    const MaxSubjectSize = 255;

    const history = useHistory();

    useEffect(() => {
        document.title = 'Contact StatusMatcher.com | StatusMatcher';
    });

    function validate(values: MessageFormValues) {
        const errors: any = {};

        if (!props.currentUserState.userFull) {
            if (!values.name) {
                errors.name = 'Required';
            }
            if (!values.email) {
                errors.email = 'Required';
            }
        }
        if (!values.subject) {
            errors.subject = 'Required';
        } else if (values.subject.length > MaxSubjectSize) {
            errors.subject = 'Too large';
        }
        if (!values.message) {
            errors.message = 'Required';
        } else if (values.message.length > MaxMessageSize) {
            errors.message = 'Too large';
        }

        return errors;
    }

    function onSubmit(messageFormValues: MessageFormValues, formikHelpers: FormikHelpers<MessageFormValues>) {
        const fetchEntityService = new FetchEntityService<Message>('message');
        const formService = new FormService<MessageFormValues>();

        const message = new Message(messageFormValues.name,
            messageFormValues.email,
            messageFormValues.subject,
            messageFormValues.message);

        fetchEntityService.newEntity(message).then(() => {
            formikHelpers.setSubmitting(false);
            history.push('/');
            setCommunicatorState(new CommunicatorState('Your message has been submitted',
                'success',
                true,
                true));
            ScrollService.scrollToTop();
        })
            .catch((error: Error) => {
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    messageFormValues,
                    false);
                ScrollService.scrollToTop();
            });
    }

    const initialValues: MessageFormValues = {
        name: '',
        email: '',
        subject: '',
        message: ''
    };

    return (
        <div>
            <h3>Contact StatusMatcher.com</h3>
            <hr/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validate={validate}
                onSubmit={onSubmit}
            >
                {formik => <Form noValidate={true}>
                    {props.currentUserState.userFull ?
                        <div className="row mb-3">
                            <div className="col-12">
                                <label htmlFor="yourName" className="form-label">Your Name</label>
                                <div>
                                    <Link to={'/user/' + props.currentUserState.userFull.id}>
                                        {props.currentUserState.userFull.name}
                                    </Link>
                                </div>
                            </div>
                        </div> :
                        <>
                            <div className="row mb-3">
                                <div className="col-lg-6 col-md-8">
                                    <FormInput label="Your Name *" name="name"/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6 col-md-8">
                                    <FormInput label="Your Email Address *" name="email"/>
                                </div>
                            </div>
                        </>
                    }
                    <div className="row mb-3">
                        <div className="col-lg-8 col-md-10">
                            <FormInput label="Subject *" name="subject"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-8 col-md-10">
                            <FormTextarea label="Message *" name="message"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                Submit Message
                                {
                                    formik.isSubmitting &&
                                    <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                                }
                            </Button>
                        </div>
                    </div>
                </Form>}
            </Formik>
        </div>
    );
}