import {EntityMetadata} from "../EntityMetadata";
import {EntitySchema} from "../../component/schema/EntitySchema";
import {FieldDefinition} from "../../component/schema/FieldDefinition";
import {FieldType} from "../../component/schema/FieldType";

export class UserFull {
    id: number | null = null;
    _metadata: EntityMetadata = new EntityMetadata();
    name: string = '';
    email: string = '';
    avatarFilename: string = '';
    enabled: boolean = false;
    roles: string[] = [];
    createdAt: string | null = null;
    updatedAt: string | null = null;

    static readonly AdminSchema: EntitySchema = new EntitySchema(
        'user',
        'users',
        [
            new FieldDefinition('id', 'ID', FieldType.Link),
            new FieldDefinition('name', 'Name', FieldType.String, 22),
            new FieldDefinition('email', 'Email', FieldType.String, 30),
            new FieldDefinition('enabled', 'Enabled', FieldType.Boolean),
            new FieldDefinition('avatarFilename', 'Image', FieldType.Avatar),
            new FieldDefinition('createdAt', 'Created', FieldType.Date),
        ]
    );
}