import {Link, useLocation} from "react-router-dom";
import {NumberState} from "../state/cache/NumberState";
import {useContext, useEffect, useState} from "react";
import {SummaryDataNumberStateService} from "../service/state/cache/SummaryDataNumberStateService";
import {CommunicatorState} from "../state/ui/CommunicatorState";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export function AdminCard() {
    const summaryDataNumberStateService = new SummaryDataNumberStateService();
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const location = useLocation();
    const [unreadReportsNumberState, setUnreadReportsNumberState] = useState(new NumberState());
    const [unreadCommentsNumberState, setUnreadCommentsNumberState] = useState(new NumberState());
    const [unreadMessagesNumberState, setUnreadMessagesNumberState] = useState(new NumberState());

    useEffect(() => {
        /*
            The admin entity page can affect the number of unread entities
            Clear and re-load the number when navigating away from the admin entity page
         */
        if (location.pathname === '/reports') {
            return () => setUnreadReportsNumberState(new NumberState(unreadReportsNumberState.value, false, false));
        }

        if (location.pathname === '/comments') {
            return () => setUnreadCommentsNumberState(new NumberState(unreadCommentsNumberState.value, false, false));
        }

        if (location.pathname === '/messages') {
            return () => setUnreadMessagesNumberState(new NumberState(unreadMessagesNumberState.value, false, false));
        }

        // eslint-disable-next-line
    }, [location.pathname, location.search]);

    // Unread report count
    useEffect(() => {
        summaryDataNumberStateService
            .refresh({entityName: 'report', summaryDataName: 'unread-count'}, unreadReportsNumberState, setUnreadReportsNumberState)
            .catch(() => setCommunicatorState(new CommunicatorState('An error occurred while loading data for the Admin card', 'danger', true)));
            // eslint-disable-next-line
    }, [unreadReportsNumberState]);

    // Unread comment count
    useEffect(() => {
        summaryDataNumberStateService
            .refresh({entityName: 'comment', summaryDataName: 'unread-count'}, unreadCommentsNumberState, setUnreadCommentsNumberState)
            .catch(() => setCommunicatorState(new CommunicatorState('An error occurred while loading data for the Admin card', 'danger', true)));
        // eslint-disable-next-line
    }, [unreadCommentsNumberState]);

    // Unread message count
    useEffect(() => {
        summaryDataNumberStateService
            .refresh({entityName: 'message', summaryDataName: 'unread-count'}, unreadMessagesNumberState, setUnreadMessagesNumberState)
            .catch(() => setCommunicatorState(new CommunicatorState('An error occurred while loading data for the Admin card', 'danger', true)));
        // eslint-disable-next-line
    }, [unreadMessagesNumberState]);

    return (
        <div className="card mb-3">
            <h6 className="card-header">Admin</h6>
            <div className="card-body">
                <div className="card-text">
                    <Link to="/reports">
                        Reports
                        {(unreadReportsNumberState.value != null && unreadReportsNumberState.value > 0) &&
                        <>
                            &nbsp;({unreadReportsNumberState.value} unread)
                        </>
                        }
                    </Link><br/>
                    <Link to="/comments">
                        Comments
                        {(unreadCommentsNumberState.value != null && unreadCommentsNumberState.value > 0) &&
                        <>
                            &nbsp;({unreadCommentsNumberState.value} unread)
                        </>
                        }
                    </Link><br/>
                    <Link to="/messages">
                        Messages
                        {(unreadMessagesNumberState.value != null && unreadMessagesNumberState.value > 0) &&
                        <>
                            &nbsp;({unreadMessagesNumberState.value} unread)
                        </>
                        }
                    </Link><br/>
                    <Link to="/users">Users</Link><br/>
                    <Link to="/general-admin">General</Link><br/>
                </div>
            </div>
        </div>
    );
}