import {FieldConfig, useField} from "formik";

export interface FormSelectProps {
    label: string;
    formText?: string;
}

export function FormSelect({label, formText, ...props}: FormSelectProps & JSX.IntrinsicElements['select'] & FieldConfig<string>) {
    const [fieldInputProps, fieldMetaProps] = useField<string>(props);
    return (
        <div>
            <label htmlFor={props.id || props.name} className="form-label">{label}</label>
            <select className={'form-select' + ((fieldMetaProps.touched && fieldMetaProps.error) ? ' is-invalid' : '')}
                {...fieldInputProps}
                {...props}/>
            {fieldMetaProps.touched && fieldMetaProps.error
                ? <div className="invalid-feedback">{fieldMetaProps.error}</div>
                : null}
            {formText
                ? <div className="form-text">{formText}</div>
                : null}
        </div>
    );
}

