import {Link} from "react-router-dom";

export function AwardsCard() {
    return (
        <div className="card mb-3">
            <h6 className="card-header">Awards</h6>
            <div className="card-body">
                <div className="card-text">
                    <Link to={'/top-contributors'}>
                        Top Contributors
                    </Link>
                </div>
            </div>
        </div>
    );
}