import {FetchEntityService} from "./FetchEntityService";
import {Program} from "../../model/program/Program";
import {ProgramFull} from "../../model/program/ProgramFull";
import {Status} from "../../model/program/Status";

export class ProgramService extends FetchEntityService<Program> {
    constructor() {
        super('program');
    }

    getStatusFromProgramFull(programFull: ProgramFull, statusId: number | null): Status | null {
        if (!statusId) {
            return null;
        }

        const status = programFull.statuses.find(status => status.id === statusId);
        return status ? status : null;
    }
}