import {CacheStateService} from "./CacheStateService";
import {CompanySummaryState} from "../../../state/cache/CompanySummaryState";
import {CompanySummary} from "../../../model/company/CompanySummary";
import {Industry} from "../../../model/Industry";
import {CompanyService} from "../../entity/CompanyService";

export class CompanySummaryStateService extends CacheStateService<CompanySummaryState, {}> {

    private createFirstLetterToCompanySummaryArrayMap(companySummaries: CompanySummary[]) {
        const firstLetterToCompanySummaryMap = new Map<string, CompanySummary[]>();
        for (const companySummary of companySummaries) {
            const firstLetter = companySummary.name.charAt(0).toUpperCase();
            if (!firstLetterToCompanySummaryMap.has(firstLetter)) {
                firstLetterToCompanySummaryMap.set(firstLetter, []);
            }
            const companySummaryArray = firstLetterToCompanySummaryMap.get(firstLetter);
            if (companySummaryArray) {
                companySummaryArray.push(companySummary);
            }
        }
        return firstLetterToCompanySummaryMap;
    }

    private compareCompanySummariesByMatchRate(companySummaryA: CompanySummary, companySummaryB: CompanySummary) {
        // Get match rates as numbers
        const matchRateA = Number(companySummaryA.matchReportPercent);
        const matchRateB = Number(companySummaryB.matchReportPercent);

        // Sort in descending order
        return matchRateB - matchRateA;
    }

    /*
        Prepare company summary data in several different formats
     */
    protected async getNewStatePromise(refreshData: object, companySummaryState: CompanySummaryState): Promise<CompanySummaryState> {
        const companyService = new CompanyService();

        const companySummaries = await companyService.getAllViews<CompanySummary>('summary');

        // For each industry, create an array of companies ordered by name
        const airlineCompanySummaries: CompanySummary[] = companySummaries.filter(cs => cs.industry === Industry.AIRLINES);
        const hotelCompanySummaries: CompanySummary[] = companySummaries.filter(cs => cs.industry === Industry.HOTEL);
        const autoCompanySummaries: CompanySummary[] = companySummaries.filter(cs => cs.industry === Industry.AUTO);

        // For airlines and hotels, create a map of letter -> array of companies whose name starts with letter
        const airlineFirstLetterToCompanySummaryMap = this.createFirstLetterToCompanySummaryArrayMap(airlineCompanySummaries);
        const hotelFirstLetterToCompanySummaryMap = this.createFirstLetterToCompanySummaryArrayMap(hotelCompanySummaries);

        // For airlines and hotels, create an array of companies ordered by match rate, highest first
        const airlineCompanySummariesByMatchRate = [...airlineCompanySummaries].sort(this.compareCompanySummariesByMatchRate);
        const hotelCompanySummariesByMatchRate = [...hotelCompanySummaries].sort(this.compareCompanySummariesByMatchRate);

        return new CompanySummaryState(airlineCompanySummaries,
            hotelCompanySummaries,
            autoCompanySummaries,
            airlineFirstLetterToCompanySummaryMap,
            hotelFirstLetterToCompanySummaryMap,
            airlineCompanySummariesByMatchRate,
            hotelCompanySummariesByMatchRate,
            false,
            true);
    }
}