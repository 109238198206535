import {ReportForm} from "../../businesscomponent/ReportForm";
import {Report} from "../../model/report/Report";
import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {ProgramAndStatusesState} from "../../state/cache/ProgramAndStatusesState";
import {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {ReportFullState} from "../../state/cache/ReportFullState";
import {ReportFullStateService} from "../../service/state/cache/ReportFullStateService";
import {FetchErrorService} from "../../service/error/FetchErrorService";
import {UserFullState} from "../../state/cache/UserFullState";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

export interface ReportEditPageProps {
    programAndStatusesState: ProgramAndStatusesState;
    currentUserState: UserFullState;
    onChange: () => void;
}

interface ReportEditPageRouteParams {
    id: string;
}

export function ReportEditPage(props: ReportEditPageProps) {
    const history = useHistory();
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const reportEditPageRouteParams = useParams<ReportEditPageRouteParams>();
    const [reportFullState, setReportFullState] = useState(new ReportFullState());

    useEffect(() => {
        if (reportEditPageRouteParams.id) {
            document.title = 'Edit Status Match Report (' + reportEditPageRouteParams.id + ') | StatusMatcher';
        } else {
            document.title = 'Edit Status Match Report | StatusMatcher';
        }
    });

    useEffect(() => {
        if (reportEditPageRouteParams.id) {
            const reportId: number = parseInt(reportEditPageRouteParams.id);
            (new ReportFullStateService()).refresh({id: reportId}, reportFullState, setReportFullState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'report');
                });
        }
    }, [reportEditPageRouteParams.id, reportFullState, setCommunicatorState]);

    function onSubmit(report: Report) {
        history.push('/report/' + report.id);
        setCommunicatorState(new CommunicatorState('Report updates saved',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
        props.onChange();
    }

    if (!reportFullState.reportFull) return null;

    return (
        <div>
            <h3>Edit Status Match Report ({reportFullState.reportFull.id})</h3>
            <hr/>
            <ReportForm
                edit={true}
                reportFull={reportFullState.reportFull}
                programAndStatusesMap={props.programAndStatusesState.programAndStatusesMap}
                onSubmit={onSubmit}
                updateCommunicatorState={setCommunicatorState}
                currentUserFull={props.currentUserState.userFull}
            />
        </div>
    );
}