import {Link} from "react-router-dom";
import * as React from "react";
import {CompanySummaryState} from "../state/cache/CompanySummaryState";
import {CompanySummary} from "../model/company/CompanySummary";
import {useEffect} from "react";

export interface SiteMapPageProps {
    companySummaryState: CompanySummaryState;
}

function getListItems(companySummaries: CompanySummary[]) {
    return companySummaries.map(cs =>
        <li key={cs.alias}><Link to={'/company/' + cs.alias}>{cs.name}</Link></li>);
}

export function SiteMapPage(props: SiteMapPageProps) {
    useEffect(() => {
        document.title = 'Site Map | StatusMatcher';
    });


    const airlineListItems = getListItems(props.companySummaryState.airlineCompanySummaries);
    const hotelListItems = getListItems(props.companySummaryState.hotelCompanySummaries);
    const autoListItems = getListItems(props.companySummaryState.autoCompanySummaries);

    return (
        <div>
            <h3>Site Map</h3>
            <hr/>
            <div className="row">
                <div className="col-md-4">
                    <Link to="/airlines">Airlines</Link>
                    <ul>{airlineListItems}</ul>
                </div>
                <div className="col-md-4">
                    <Link to="/hotels">Hotels</Link>
                    <ul>{hotelListItems}</ul>
                </div>
                <div className="col-md-4">
                    <Link to="/auto">Auto Rental</Link>
                    <ul>{autoListItems}</ul>
                    <Link to="/guide">Guide</Link><br/>
                    <Link to="/report/create">Create Report</Link><br/>
                    <Link to="/about">About</Link><br/>
                    <Link to="/contact">Contact</Link><br/>
                    <Link to="/terms">Terms and Conditions</Link>
                </div>
            </div>
        </div>
    );
}