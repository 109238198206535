import * as H from "history";
import {PageMetadata} from "../model/PageMetadata";

export class PaginationService {
    static readonly DefaultPageSize = 8;

    static getPageFromLocation(location: H.Location<H.LocationState>, pageParam: string = 'page'): number {
        const urlSearchParams = new URLSearchParams(location.search);
        const pageSearchParam: (string | null) = urlSearchParams.get(pageParam);
        return (pageSearchParam === null) ? 0 : parseInt(pageSearchParam);
    }

    static handlePreviousPage(location: H.Location<H.LocationState>,
                              history: H.History<H.LocationState>,
                              currentPage: number,
                              pageParam: string = 'page') {
        const urlSearchParams = new URLSearchParams(location.search);
        const targetPage = currentPage - 1;

        if (targetPage > 0) {
            urlSearchParams.set(pageParam, targetPage.toString());
        } else {
            urlSearchParams.delete(pageParam);
        }

        history.push({
            pathname: location.pathname,
            search: urlSearchParams.toString()
        });
    }

    static handleNextPage(location: H.Location<H.LocationState>,
                          history: H.History<H.LocationState>,
                          currentPage: number,
                          pageParam: string = 'page') {
        const urlSearchParams = new URLSearchParams(location.search);
        const targetPage = currentPage + 1;

        urlSearchParams.set(pageParam, targetPage.toString());

        history.push({
            pathname: location.pathname,
            search: urlSearchParams.toString()
        });
    }

    static isPreviousPageEnabled(pageMetadata: PageMetadata): boolean {
        return pageMetadata.number !== 0;
    }

    static isNextPageEnabled(pageMetadata: PageMetadata): boolean {
        const firstListItemIndex = (pageMetadata.number * pageMetadata.size) + 1;
        const lastListItemIndex = Math.min(firstListItemIndex + pageMetadata.size - 1, pageMetadata.totalElements);
        return lastListItemIndex < pageMetadata.totalElements;
    }
}