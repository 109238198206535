import {LoginForm} from "../../businesscomponent/LoginForm";
import {UserFullState} from "../../state/cache/UserFullState";
import {UserFull} from "../../model/user/UserFull";
import {useEffect} from "react";
import {ScrollService} from "../../service/ScrollService";
import {useHistory} from "react-router";
import {useLastLocation} from "react-router-last-location";

export interface LoginPageProps {
    setCurrentUserState: (currentUserState: UserFullState) => void;
}

export function LoginPage(props: LoginPageProps) {
    const history = useHistory();
    const lastLocation = useLastLocation();

    useEffect(() => {
        document.title = 'Login | StatusMatcher';
    });

    function onAuthenticate(userFull: UserFull) {
        // If possible, return to previous page after login
        if (lastLocation) {
            history.push(lastLocation);
        } else {
            history.push('/');
            ScrollService.scrollToTop();
        }
        props.setCurrentUserState(new UserFullState(userFull, false, true));
    }

    return (
        <div>
            <h3>Login</h3>
            <hr/>
            <LoginForm onAuthenticate={onAuthenticate}/>
        </div>
    );
}