import {CacheStateService} from "./CacheStateService";
import {ReportVoteState} from "../../../state/cache/ReportVoteState";
import {VoteService} from "../../entity/VoteService";

interface ReportVoteStateRefreshData {
    reportId: number;
}

export class ReportVoteStateService extends CacheStateService<ReportVoteState, ReportVoteStateRefreshData> {
    protected isRefreshNeededStepTwo(refreshData: ReportVoteStateRefreshData, reportVoteState: ReportVoteState) {
        return (refreshData.reportId !== reportVoteState.reportId);
    }

    protected async getNewStatePromise(refreshData: ReportVoteStateRefreshData, reportVoteState: ReportVoteState): Promise<ReportVoteState> {
        const voteService = new VoteService();
        const vote = await voteService.getVoteByReport(refreshData.reportId);

        return new ReportVoteState(refreshData.reportId, vote,false,true);
    }
}