import {FetchEntityService} from "./FetchEntityService";
import {Report} from "../../model/report/Report";
import {Result} from "../../model/report/Result";
import {PagedModel} from "../../model/PagedModel";
import {ReportFull} from "../../model/report/ReportFull";
import {Status} from "../../model/program/Status";
import {ProgramService} from "./ProgramService";
import {CommentFull} from "../../model/comment/CommentFull";
import {ReportSummary} from "../../model/report/ReportSummary";
import {ReportFullState} from "../../state/cache/ReportFullState";

export class ReportService extends FetchEntityService<Report> {
    static readonly MaxSize = 10000;

    constructor() {
        super('report');
    }

    formatResult(result: Result): string {
        // MATCH -> Match
        return result.substr(0, 1) + result.substr(1).toLowerCase();
    }

    resultFromString(resultString: string): Result {
        switch (resultString) {
            case 'DENY':
                return Result.DENY;
            case 'CHALLENGE':
                return Result.CHALLENGE;
            case 'OTHER':
                return Result.OTHER;
        }
        return Result.MATCH;
    }

    async getPageViewsByUser<VIEW_TYPE>(page: number, size: number, view: string, userId: number): Promise<PagedModel<VIEW_TYPE>> {
        const url = this.baseURL + '?page=' + page.toString()
            + '&size=' + size.toString()
            + '&view=' + view
            + '&userId=' + userId.toString();
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async getPageViewsByProgram<VIEW_TYPE>(page: number, size: number, view: string, programId: number, to: boolean): Promise<PagedModel<VIEW_TYPE>> {
        const url = this.baseURL + '?page=' + page.toString()
            + '&size=' + size.toString()
            + '&view=' + view
            + '&programId=' + programId.toString()
            + '&to=' + to;
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    getStatusFromReportFull(reportFull: ReportFull, from: boolean): Status | null {
        const statusId = from ? reportFull.fromStatusId : reportFull.toStatusId;
        if (!statusId) {
            return null;
        }

        const programFull = from ? reportFull.fromProgram : reportFull.toProgram;
        if (!programFull) {
            return null;
        }

        const programService = new ProgramService();
        return programService.getStatusFromProgramFull(programFull, statusId);
    }

    getStatusNameFromReportFull(reportFull: ReportFull, from: boolean): string {
        const status = this.getStatusFromReportFull(reportFull, from);
        return status ? status.name : '';
    }

    async getCommentFullsByReport(reportId: number): Promise<CommentFull[]> {
        const url = this.baseURL + '/' + reportId.toString() + '/comments';
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    getVoteDescription(reportSummary: ReportSummary): string {
        const voteCount: number = reportSummary.voteUpCount - reportSummary.voteDownCount;
        if (voteCount === 0) {
            return '';
        } else if (voteCount === 1) {
            return '1 vote';
        } else if (voteCount === -1) {
            return '-1 vote';
        } else {
            return voteCount.toString() + ' votes';
        }
    }

    getCommentDescription(reportSummary: ReportSummary): string {
        if (reportSummary.commentCount === 0) {
            return '';
        } else if (reportSummary.commentCount === 1) {
            return '1 comment';
        } else {
            return reportSummary.commentCount.toString() + ' comments';
        }
    }

    /*
        Front end only updating of vote counts. Meant for optimistic updating.
     */
    updateVoteCount(reportFullState: ReportFullState,
                    setReportFullState: (reportFullState: ReportFullState) => void,
                    up: boolean,
                    numericChange: number): ReportFullState {
        if (!reportFullState.reportFull) return reportFullState;

        const reportFullUpdated = up ? {
            ...reportFullState.reportFull,
            voteUpCount: reportFullState.reportFull.voteUpCount + numericChange
        } : {
            ...reportFullState.reportFull,
            voteDownCount: reportFullState.reportFull.voteDownCount + numericChange
        };

        const reportFullStateUpdated = {
            ...reportFullState,
            reportFull: reportFullUpdated
        };

        setReportFullState(reportFullStateUpdated);
        return reportFullStateUpdated;
    }
}