import {useEffect} from "react";

export function AboutPage() {
    useEffect(() => {
        document.title = 'About StatusMatcher.com | StatusMatcher';
    });


    return (
        <div>
            <h3>About StatusMatcher.com</h3>
            <hr/>
            <h4>Site History</h4>
            <p>
                StatusMatcher was created in 2007 as a way to share tips amongst a group of frequent business travelers. Through experimentation they realized that status levels, and the valuable benefits they come with, could be transferred from one travel firm to another. The goal of the site is to share this ability to transfer status levels with other frequent travelers, and learn about others' experiences with this process.
            </p>
            <p>
                The site has expanded from an initial focus on US-based airlines to a world-wide coverage of air, hotel, and rental car firms.
            </p>

            <h4>Content</h4>
            <p>
                The content on this site is primarily provided by site visitors sharing their experiences requesting status matches.
            </p>
        </div>
    );
}