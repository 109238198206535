import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {FormInput} from "../component/form/FormInput";
import {Button, Spinner} from "react-bootstrap";
import {UserService} from "../service/entity/UserService";
import {FormService} from "../service/FormService";
import {AuthenticationError} from "../service/error/AuthenticationError";
import {FormCheckbox} from "../component/form/FormCheckbox";
import {UserFull} from "../model/user/UserFull";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

interface LoginFormValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface LoginFormProps {
    onAuthenticate: (userFull: UserFull) => void;
}

export function LoginForm(props: LoginFormProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const initialValues: LoginFormValues = {
        email: '',
        password: '',
        rememberMe: false,
    };

    function handleSubmit(loginFormValues: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>) {
        const userService = new UserService();

        userService.login(loginFormValues.email, loginFormValues.password, loginFormValues.rememberMe)
            .then((userFull: UserFull) => {
                formikHelpers.setSubmitting(false);
                if (props.onAuthenticate) {
                    props.onAuthenticate(userFull);
                }
            })
            .catch((error: Error) => {
                if (error instanceof AuthenticationError) {
                    error.message = 'Login error: the email address and password combination were not valid';
                }
                const formService = new FormService<LoginFormValues>();
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    loginFormValues,
                    false);
            });
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                email: Yup.string().required('Required'),
                password: Yup.string().required('Required'),
                rememberMe: Yup.boolean()
            })}
            onSubmit={handleSubmit}>
            {formik => (
                <Form noValidate={true}>
                    <div className="row mb-3">
                        <div className="col-lg-6 col-md-8">
                            <FormInput label="Email Address *" name="email"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-6 col-md-8">
                            <FormInput label="Password *" name="password" type="password"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-6 col-md-8">
                            <div className="row">
                                <div className="col">
                                    <FormCheckbox label="Remember Me" name="rememberMe"/>
                                </div>
                                <div className="col text-end">
                                    <Link to="/reset-password">Forgot password?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                Login
                                {
                                    formik.isSubmitting &&
                                    <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                                }
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}