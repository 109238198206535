export class DateService {
    /*
        ISO String: 2021-08-10T15:50:18Z
        to
        Date Display String: August 10, 2021
     */
    ISOStringToDateDisplayString(ISOString: string | null): string {
        if (ISOString === null)
            return '';

        const date = new Date(ISOString);
        return date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    }

    ISOStringToShortDateDisplayString(ISOString: string | null): string {
        if (ISOString === null)
            return '';

        const date = new Date(ISOString);
        return date.toLocaleDateString('en-US', {
            dateStyle: 'short'
        });
    }

    /*
        ISO String: 2021-08-10T15:50:18Z
        to
        Date Display String: Aug 10, 2021, 4:33 PM
     */
    ISOStringToDateTimeDisplayString(ISOString: string | null): string {
        if (ISOString === null)
            return '';

        const date = new Date(ISOString);
        return date.toLocaleString('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short'
        });
    }
}