import {EntityMetadata} from "../EntityMetadata";
import {User} from "../user/User";
import {Report} from "../report/Report";
import {EntitySchema} from "../../component/schema/EntitySchema";
import {FieldDefinition} from "../../component/schema/FieldDefinition";
import {FieldType} from "../../component/schema/FieldType";

export class CommentFull {
    id: number | null = null;
    _metadata: EntityMetadata = new EntityMetadata();
    body: string = '';
    user: User | null = null;
    parentId: number | null = null;
    report: Report | null = null;
    deleted: boolean = false;
    depth: number = 0;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    static readonly AdminSchema: EntitySchema = new EntitySchema(
        'comment',
        'comments',
        [
            new FieldDefinition('id', 'ID', FieldType.Number),
            new FieldDefinition('report', 'Location', FieldType.Report),
            new FieldDefinition('body', 'Comment', FieldType.String, 40),
            new FieldDefinition('user', 'User', FieldType.User),
            new FieldDefinition('createdAt', 'Created', FieldType.Date),
        ]
    );
}