import {CacheState} from "../../../state/cache/CacheState";

export abstract class CacheStateService<STATE_TYPE extends CacheState, REFRESH_DATA_TYPE> {
    /*
        Override with logic to fetch state
     */
    protected abstract getNewStatePromise(refreshData: REFRESH_DATA_TYPE, state: STATE_TYPE): Promise<STATE_TYPE>;

    /*
        Override with domain-specific logic to determine if a state refresh is needed
     */
    protected isRefreshNeededStepTwo(refreshData: REFRESH_DATA_TYPE, state: STATE_TYPE): boolean {
        return false;
    }

    protected isRefreshNeeded(refreshData: REFRESH_DATA_TYPE, state: STATE_TYPE): boolean {
        // If there is a pending requests, don't create another one
        if (state.pendingRequest) {
            return false;
        }

        // If state is not loaded with data, load it
        if (!state.loaded) {
            return true;
        }

        // Otherwise decide based on other factors
        return this.isRefreshNeededStepTwo(refreshData, state);
    }

    public refresh(refreshData: REFRESH_DATA_TYPE,
            state: STATE_TYPE,
            setState: (state: STATE_TYPE) => void): Promise<STATE_TYPE | null> {
        if (this.isRefreshNeeded(refreshData, state)) {
            // Set pending request flag, to avoid duplicate requests
            setState({...state, pendingRequest: true});

            return this.getNewStatePromise(refreshData, state)
                .then(state => {
                    setState(state);
                    return state;
                })
        } else {
            return Promise.resolve(null);
        }
    }
}