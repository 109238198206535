import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import {AirlinePage} from "./page/AirlinePage";
import {HotelPage} from "./page/HotelPage";
import {AutoPage} from "./page/AutoPage";
import {NavigationBar} from "./businesscomponent/NavigationBar";
import {Col, Container, Row} from "react-bootstrap";
import {GuidePage} from "./page/GuidePage";
import {ReportCreatePage} from "./page/report/ReportCreatePage";
import {Communicator} from "./component/Communicator";
import {CommunicatorState} from "./state/ui/CommunicatorState";
import {ReportListState} from "./state/cache/ReportListState";
import {HomePage} from "./page/HomePage";
import {Footer} from "./businesscomponent/Footer";
import {AboutPage} from "./page/AboutPage";
import {TermsPage} from "./page/TermsPage";
import {SiteMapPage} from "./page/SiteMapPage";
import {CompanySummaryState} from "./state/cache/CompanySummaryState";
import {CompanySummaryStateService} from "./service/state/cache/CompanySummaryStateService";
import {ProgramAndStatusesState} from "./state/cache/ProgramAndStatusesState";
import {ProgramAndStatusesStateService} from "./service/state/cache/ProgramAndStatusesStateService";
import {UserCard} from "./businesscomponent/UserCard";
import {CurrentUserStateService} from "./service/state/cache/CurrentUserStateService";
import {LoginPage} from "./page/user/LoginPage";
import {UserPage} from "./page/user/UserPage";
import {RecentReportListStateService} from "./service/state/cache/RecentReportListStateService";
import {PaginationService} from "./service/PaginationService";
import {UserEditPage} from "./page/user/UserEditPage";
import {UserFullState} from "./state/cache/UserFullState";
import {NotFoundPage} from "./page/NotFoundPage";
import {Authenticate} from "./service/Authenticate";
import {UserRegistrationPage} from "./page/user/UserRegistrationPage";
import {UserRegistrationTokenPage} from "./page/user/UserRegistrationTokenPage";
import {ChangePasswordPage} from "./page/user/ChangePasswordPage";
import {ResetPasswordPage} from "./page/user/ResetPasswordPage";
import {ResetPasswordTokenPage} from "./page/user/ResetPasswordTokenPage";
import {ReportPage} from "./page/report/ReportPage";
import {ReportEditPage} from "./page/report/ReportEditPage";
import {CompanyPage} from "./page/CompanyPage";
import {TopContributorsPage} from "./page/TopContributorsPage";
import {AwardsCard} from "./businesscomponent/AwardsCard";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faThumbsDown, faThumbsUp, faCheckCircle} from '@fortawesome/free-regular-svg-icons'
import {
    faBook,
    faEdit,
    faThumbsDown as faThumbsDownSolid,
    faThumbsUp as faThumbsUpSolid,
    faTrash
} from '@fortawesome/free-solid-svg-icons'
import {MessagePage} from "./page/message/MessagePage";
import {MessageCreatePage} from "./page/message/MessageCreatePage";
import {AdminCard} from "./businesscomponent/AdminCard";
import {EntityListPage} from "./page/EntityListPage";
import {ReportSummary} from "./model/report/ReportSummary";
import {CommentFull} from "./model/comment/CommentFull";
import {MessageFull} from "./model/message/MessageFull";
import {SetCommunicatorStateContext} from "./context/SetCommunicatorStateContext";
import {ConfirmModal} from "./component/modal/ConfirmModal";
import {ConfirmModalContext} from "./context/ConfirmModalContext";
import {UserFull} from "./model/user/UserFull";
import {GeneralAdminPage} from "./page/GeneralAdminPage";
import {LastLocationProvider} from "react-router-last-location";

export function App() {
    // React hooks and state
    const location = useLocation();

    const homePageRouteMatch = useRouteMatch({path: '/', exact: true});
    const airlinesMapPageRouteMatch = useRouteMatch({path: '/airlines', exact: true});
    const hotelsMapPageRouteMatch = useRouteMatch({path: '/hotels', exact: true});
    const autoMapPageRouteMatch = useRouteMatch({path: '/auto', exact: true});
    const siteMapPageRouteMatch = useRouteMatch({path: '/sitemap', exact: true});
    const reportCreatePageRouteMatch = useRouteMatch({path: '/report/create', exact: true});
    const reportEditPageRouteMatch = useRouteMatch({path: '/report/:id/edit', exact: true});
    const companyPageRouteMatch = useRouteMatch({path: '/company/:alias', exact: true});

    // Top level application state
    /*
        Keeping state here can improve performance by avoiding re-loading state from server
        Even as the page/route changes, this state is preserved as part of the top level App component
        If it was page level or lower, it would be lost
     */
    const [communicatorState, setCommunicatorState] = useState(new CommunicatorState());
    const [recentReportListState, setRecentReportListState] = useState(new ReportListState());
    const [companySummaryState, setCompanySummaryState] = useState(new CompanySummaryState());
    const [programAndStatusesState, setProgramAndStatusesState] = useState(new ProgramAndStatusesState());
    const [currentUserState, setCurrentUserState] = useState(new UserFullState());

    // Confirm modal state
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalText, setConfirmModalText] = useState('');
    const [onConfirmModalConfirm, setOnConfirmModalConfirm] = useState(() => () => Promise.resolve(false));

    function handleRefreshError() {
        setCommunicatorState(new CommunicatorState('An error occurred while loading the page', 'danger', true));
    }

    /*
        Do any required side effects when app is loaded or props have changed
        This refreshes state required for a given page
     */
    function doEffects() {
        (new CurrentUserStateService()).refresh({}, currentUserState, setCurrentUserState)
            .catch(handleRefreshError);

        if (homePageRouteMatch) {
            const recentReportListStateService = new RecentReportListStateService();

            recentReportListStateService.refresh({page: PaginationService.getPageFromLocation(location)}, recentReportListState, setRecentReportListState)
                .catch(handleRefreshError);
        }
        if (airlinesMapPageRouteMatch || hotelsMapPageRouteMatch || autoMapPageRouteMatch || siteMapPageRouteMatch) {
            (new CompanySummaryStateService()).refresh({}, companySummaryState, setCompanySummaryState)
                .catch(handleRefreshError);
        }
        if (reportCreatePageRouteMatch || reportEditPageRouteMatch || companyPageRouteMatch) {
            (new ProgramAndStatusesStateService()).refresh({}, programAndStatusesState, setProgramAndStatusesState)
                .catch(handleRefreshError);
        }
    }

    useEffect(doEffects);

    /*
        Do any required side effects when the router location path (URL) has changed
     */
    function doLocationPathChangeEffects() {
        // Reset communicator on any page change
        setCommunicatorState((previousCommunicatorState: CommunicatorState) => {
            if (previousCommunicatorState.keepVisibleForOnePageChange) {
                return {...previousCommunicatorState, keepVisibleForOnePageChange: false};
            } else {
                return new CommunicatorState()
            }
        });
    }

    useEffect(doLocationPathChangeEffects, [location.pathname]);

    function handleCommunicatorClose() {
        setCommunicatorState(new CommunicatorState('', 'primary', false));
    }

    /*
        When data has changed, clear appropriate front end cache state
     */
    function onUserChange() {
        setRecentReportListState(new ReportListState());
        setCompanySummaryState(new CompanySummaryState());
        setProgramAndStatusesState(new ProgramAndStatusesState());
        setCurrentUserState(new UserFullState());
    }

    function onReportChange() {
        setRecentReportListState(new ReportListState());
    }

    function onConfirmModalConfirmOuter() {
        onConfirmModalConfirm().then((success: boolean) => {
            // Only close modal if action succeeded
            if (success) {
                setShowConfirmModal(false);
            }
        });
    }

    return (
        <div>
            <SetCommunicatorStateContext.Provider value={setCommunicatorState}>
                <ConfirmModalContext.Provider
                    value={{setShowConfirmModal, setConfirmModalText, setOnConfirmModalConfirm}}>
                    <LastLocationProvider>
                        <NavigationBar currentUserState={currentUserState}/>
                        <Container>
                            <Row>
                                <Col lg={9}>
                                    <Communicator communicatorState={communicatorState}
                                                  onClose={handleCommunicatorClose}/>
                                    <Switch>
                                        <Route exact path="/">
                                            <HomePage recentReportState={recentReportListState}/>
                                        </Route>
                                        <Route exact path="/airlines">
                                            <AirlinePage companySummaryState={companySummaryState}/>
                                        </Route>
                                        <Route exact path="/hotels">
                                            <HotelPage companySummaryState={companySummaryState}/>
                                        </Route>
                                        <Route exact path="/auto">
                                            <AutoPage companySummaryState={companySummaryState}/>
                                        </Route>
                                        <Route exact path="/guide"><GuidePage/></Route>
                                        <Route exact path="/company/:alias">
                                            <CompanyPage programAndStatusesState={programAndStatusesState}
                                                         currentUserState={currentUserState}
                                            />
                                        </Route>

                                        {/* Reports */}
                                        <Route exact path="/report/create">
                                            <ReportCreatePage programAndStatusesState={programAndStatusesState}
                                                              currentUserState={currentUserState}
                                            />
                                        </Route>
                                        <Route exact path="/report/:id/edit">
                                            <Authenticate currentUserState={currentUserState} allowAdmin>
                                                <ReportEditPage programAndStatusesState={programAndStatusesState}
                                                                currentUserState={currentUserState}
                                                                onChange={onReportChange}
                                                />
                                            </Authenticate>
                                        </Route>
                                        <Route exact path="/report/:id">
                                            <ReportPage currentUserState={currentUserState}
                                                        onChange={onReportChange}
                                            />
                                        </Route>
                                        <Route exact path="/reports">
                                            <Authenticate currentUserState={currentUserState} allowAdmin>
                                                <EntityListPage<ReportSummary> entitySchema={ReportSummary.AdminSchema}
                                                                               entityView="adminReportList"
                                                                               onChange={onReportChange}
                                                                               highlightUnread={true}
                                                                               displayedActions={['publish', 'edit']}
                                                />
                                            </Authenticate>
                                        </Route>

                                        <Route exact path="/comments">
                                            <Authenticate currentUserState={currentUserState} allowAdmin>
                                                <EntityListPage<CommentFull> entitySchema={CommentFull.AdminSchema}
                                                                             entityView="adminCommentList"
                                                                             highlightUnread={true}
                                                                             displayedActions={['delete']}
                                                />
                                            </Authenticate>
                                        </Route>
                                        <Route exact path="/about"><AboutPage/></Route>
                                        <Route exact path="/sitemap">
                                            <SiteMapPage companySummaryState={companySummaryState}/>
                                        </Route>
                                        <Route exact path="/terms"><TermsPage/></Route>

                                        {/* Users */}
                                        <Route exact path="/user/:id">
                                            <UserPage currentUserState={currentUserState}/>
                                        </Route>
                                        <Route exact path="/user/:id/edit">
                                            <Authenticate currentUserState={currentUserState} allowAdmin allowSelf>
                                                <UserEditPage
                                                    currentUserState={currentUserState}
                                                    onChange={onUserChange}
                                                />
                                            </Authenticate>
                                        </Route>
                                        <Route exact path="/users">
                                            <Authenticate currentUserState={currentUserState} allowAdmin>
                                                <EntityListPage<UserFull> entitySchema={UserFull.AdminSchema}
                                                                          entityView="adminUserList"
                                                                          displayedActions={['edit']}
                                                />
                                            </Authenticate>
                                        </Route>

                                        <Route exact path="/login">
                                            <LoginPage setCurrentUserState={setCurrentUserState}/>
                                        </Route>
                                        <Route exact path="/register">
                                            <UserRegistrationPage/>
                                        </Route>
                                        <Route exact path="/register-token/:token">
                                            <UserRegistrationTokenPage setCurrentUserState={setCurrentUserState}/>
                                        </Route>
                                        <Route exact path="/change-password">
                                            <Authenticate currentUserState={currentUserState} allowAuthenticated>
                                                <ChangePasswordPage/>
                                            </Authenticate>
                                        </Route>
                                        <Route exact path="/reset-password">
                                            <ResetPasswordPage/>
                                        </Route>
                                        <Route exact path="/reset-password-token/:token">
                                            <ResetPasswordTokenPage/>
                                        </Route>
                                        <Route exact path="/top-contributors">
                                            <TopContributorsPage/>
                                        </Route>
                                        <Route exact path="/message/:id">
                                            <Authenticate currentUserState={currentUserState} allowAdmin>
                                                <MessagePage/>
                                            </Authenticate>
                                        </Route>

                                        {/* Messages */}
                                        <Route exact path="/contact">
                                            <MessageCreatePage currentUserState={currentUserState}/>
                                        </Route>
                                        <Route exact path="/messages">
                                            <Authenticate currentUserState={currentUserState} allowAdmin>
                                                <EntityListPage<MessageFull> entitySchema={MessageFull.AdminSchema}
                                                                             entityView="adminMessageList"
                                                                             highlightUnread={true}
                                                                             displayedActions={['delete']}
                                                />
                                            </Authenticate>
                                        </Route>

                                        <Route exact path="/general-admin"><GeneralAdminPage/></Route>
                                        <Route path="/"><NotFoundPage/></Route>
                                    </Switch>
                                </Col>
                                <Col lg={3} className={"d-none d-lg-block"}>
                                    <UserCard currentUserState={currentUserState}
                                              setCurrentUserState={setCurrentUserState}/>
                                    <Authenticate currentUserState={currentUserState} showPageNotFound={false}
                                                  allowAdmin>
                                        <AdminCard/>
                                    </Authenticate>
                                    <Route exact path="/"><AwardsCard/></Route>
                                </Col>
                            </Row>
                            <Row>
                                <Footer/>
                            </Row>
                        </Container>
                        <ConfirmModal
                            show={showConfirmModal}
                            text={confirmModalText}
                            onConfirm={onConfirmModalConfirmOuter}
                            onClose={() => setShowConfirmModal(false)}
                        />
                    </LastLocationProvider>
                </ConfirmModalContext.Provider>
            </SetCommunicatorStateContext.Provider>
        </div>
    );
}

library.add(faThumbsUp, faThumbsDown, faThumbsUpSolid, faThumbsDownSolid, faBook, faEdit, faTrash,
    faCheckCircle);