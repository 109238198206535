import {CacheStateService} from "./CacheStateService";
import {ReportListState} from "../../../state/cache/ReportListState";
import {PagedModel} from "../../../model/PagedModel";
import {ReportSummary} from "../../../model/report/ReportSummary";
import {ReportService} from "../../entity/ReportService";
import {PaginationService} from "../../PaginationService";

interface RecentReportListStateRefreshData {
    page: number;
}

export class RecentReportListStateService extends CacheStateService<ReportListState, RecentReportListStateRefreshData> {
    private static readonly View = 'recentReportList';

    protected isRefreshNeededStepTwo(refreshData: RecentReportListStateRefreshData, recentReportState: ReportListState) {
        // If requested page is different than current page, we need to refresh it
        return (refreshData.page !== recentReportState.pageMetadata.number);
    }

    protected async getNewStatePromise(refreshData: RecentReportListStateRefreshData, recentReportState: ReportListState): Promise<ReportListState> {
        const reportService = new ReportService();

        const reportSummaryPagedModel: PagedModel<ReportSummary> = await reportService.getPageViews<ReportSummary>(
            refreshData.page,
            PaginationService.DefaultPageSize,
            RecentReportListStateService.View);

        // Handle case of no data returned
        const reportSummaries: ReportSummary[] = (reportSummaryPagedModel.collection)
            ? reportSummaryPagedModel.collection
            : [];

        return new ReportListState(reportSummaries, reportSummaryPagedModel._metadata, false, true);
    }
}