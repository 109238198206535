import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {useHistory, useParams} from "react-router";
import {ResetPasswordTokenForm} from "../../businesscomponent/ResetPasswordTokenForm";
import {useContext, useEffect} from "react";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

interface ResetPasswordTokenPageRouteParams {
    token: string;
}

export function ResetPasswordTokenPage() {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const history = useHistory();
    const resetPasswordTokenPageRouteParams = useParams<ResetPasswordTokenPageRouteParams>();

    useEffect(() => {
        document.title = 'Reset Password | StatusMatcher';
    });

    function onSubmit() {
        history.push('/');
        setCommunicatorState(new CommunicatorState(
            'Your password has been updated',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
    }

    return (
        <div>
            <h3>Reset Password</h3>
            <hr/>
            <ResetPasswordTokenForm
                token={resetPasswordTokenPageRouteParams.token}
                onSubmit={onSubmit}
            />
        </div>
    );
}