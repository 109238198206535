import {useHistory, useParams} from "react-router";
import {useContext, useEffect, useState} from "react";
import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {UserForm} from "../../businesscomponent/UserForm";
import {UserFullState} from "../../state/cache/UserFullState";
import {UserFullStateService} from "../../service/state/cache/UserFullStateService";
import {UserFull} from "../../model/user/UserFull";
import {FetchErrorService} from "../../service/error/FetchErrorService";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

export interface UserEditPageProps {
    currentUserState: UserFullState;
    onChange: () => void;
}

interface UserEditPageRouteParams {
    id: string;
}

export function UserEditPage(props: UserEditPageProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const history = useHistory();
    const userPageRouteParams = useParams<UserEditPageRouteParams>();
    const [userFullState, setUserFullState] = useState(new UserFullState());

    useEffect(() => {
        document.title = 'Edit User | StatusMatcher';
    });

    function doEffects() {
        if (userPageRouteParams.id) {
            const userId: number = parseInt(userPageRouteParams.id);
            (new UserFullStateService()).refresh({id: userId}, userFullState, setUserFullState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'user');
                });
        }
    }

    useEffect(doEffects);

    function onSubmit(userFull: UserFull) {
        history.push('/user/' + userFull.id);
        setCommunicatorState(new CommunicatorState('User \'' + userFull.name + '\' has been updated',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
        if (props.onChange) {
            props.onChange();
        }
    }

    // Handle case where user is not loaded
    if (!userFullState.userFull) return null;

    return (
        <div>
            <h3>Edit User: {userFullState.userFull.name}</h3>
            <hr/>
            <UserForm
                currentUserState={props.currentUserState}
                userFull={userFullState.userFull}
                onSubmit={onSubmit}
            />
        </div>
    );
}