import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {useParams} from "react-router";
import {ReactElement, SyntheticEvent, useContext, useEffect, useState} from "react";
import {ReportFullState} from "../../state/cache/ReportFullState";
import {ReportFullStateService} from "../../service/state/cache/ReportFullStateService";
import {ReportFull} from "../../model/report/ReportFull";
import {ReportService} from "../../service/entity/ReportService";
import {Link} from "react-router-dom";
import {Status} from "../../model/program/Status";
import {AppConfig} from "../../AppConfig";
import {DateService} from "../../service/DateService";
import {FetchErrorService} from "../../service/error/FetchErrorService";
import {StatusService} from "../../service/entity/StatusService";
import {ReportCommentFullListState} from "../../state/cache/ReportCommentFullListState";
import {ReportCommentFullListStateService} from "../../service/state/cache/ReportCommentFullListStateService";
import {CommentList} from "../../businesscomponent/CommentList";
import {UserFullState} from "../../state/cache/UserFullState";
import {CommentForm} from "../../businesscomponent/CommentForm";
import {Comment} from "../../model/comment/Comment";
import {Voting} from "../../component/Voting";
import {ReportVoteState} from "../../state/cache/ReportVoteState";
import {ReportVoteStateService} from "../../service/state/cache/ReportVoteStateService";
import {VoteService} from "../../service/entity/VoteService";
import {EntityActions} from "../../component/EntityActions";
import {ReportSummary} from "../../model/report/ReportSummary";
import {BaseEntity} from "../../model/BaseEntity";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";
import Linkify from 'linkify-react';

export interface ReportPageProps {
    currentUserState: UserFullState;
    onChange: () => void;
}

interface ReportPageRouteParams {
    id: string;
}

export function ReportPage(props: ReportPageProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const reportPageRouteParams = useParams<ReportPageRouteParams>();
    const [reportFullState, setReportFullState] = useState(new ReportFullState());
    const [reportCommentFullListState, setReportCommentFullListState] = useState(new ReportCommentFullListState());
    const [reportVoteState, setReportVoteState] = useState(new ReportVoteState());

    const reportService = new ReportService();
    const voteService = new VoteService();

    // Page title
    useEffect(() => {
        if (reportFullState.reportFull && reportFullState.reportFull.toProgram) {
            document.title = reportFullState.reportFull.toProgram.name + ' Report: ' + reportService.formatResult(reportFullState.reportFull.result);
        } else {
            document.title = 'Report | StatusMatcher';
        }
    });

    // Report state
    useEffect(() => {
        if (reportPageRouteParams.id) {
            const reportId: number = parseInt(reportPageRouteParams.id);
            (new ReportFullStateService()).refresh({id: reportId}, reportFullState, setReportFullState)
                .then((updatedReportFullState: ReportFullState | null) => {
                    if (updatedReportFullState && updatedReportFullState.reportFull && !updatedReportFullState.reportFull.published) {
                        setCommunicatorState(new CommunicatorState('Unpublished Report', 'warning', true));
                    }
                })
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'report');
                });
        }
    }, [reportPageRouteParams.id, reportFullState, setCommunicatorState]);

    // Comments state
    useEffect(() => {
        if (reportPageRouteParams.id) {
            const reportId: number = parseInt(reportPageRouteParams.id);
            (new ReportCommentFullListStateService()).refresh({reportId}, reportCommentFullListState, setReportCommentFullListState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'comment');
                });
        }
    }, [reportPageRouteParams.id, reportCommentFullListState, setCommunicatorState]);

    // Voting state
    useEffect(() => {
        if (reportPageRouteParams.id) {
            (new ReportVoteStateService()).refresh(
                {reportId: parseInt(reportPageRouteParams.id)},
                reportVoteState,
                setReportVoteState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'vote');
                });
        }
    }, [reportPageRouteParams.id, setCommunicatorState, reportVoteState]);

    // Handle case where report is not loaded
    if (!reportFullState.reportFull) return null;

    const votingActive = (props.currentUserState.userFull != null && reportVoteState.loaded);

    function onVote(e: SyntheticEvent, up: boolean) {
        e.preventDefault();

        if (reportPageRouteParams.id && reportFullState.reportFull) {
            voteService.onUserReportVote(up,
                reportVoteState,
                setReportVoteState,
                setCommunicatorState,
                parseInt(reportPageRouteParams.id),
                reportFullState,
                setReportFullState
            );
        }

        props.onChange();
    }

    function onCommentSubmit(comment: Comment) {
        // Refresh comment section
        setReportCommentFullListState({...reportCommentFullListState, loaded: false});
    }

    function onCommentDelete(commentId: number) {
        // Refresh comment section
        setReportCommentFullListState({...reportCommentFullListState, loaded: false});
    }

    function onPublish(baseEntity: BaseEntity) {
        // Update state directly
        if (reportFullState.reportFull) {
            const reportFullUpdated: ReportFull = {
                ...reportFullState.reportFull,
                published: true
            };
            setReportFullState(new ReportFullState(reportFullUpdated, false, true));
            setCommunicatorState(new CommunicatorState('Report published', 'success', true));
            props.onChange();
        }
    }

    function renderStatusRow(fromStatus: Status | null, fromStatusSelected: boolean, toStatus: Status | null, toStatusSelected: boolean) {
        const statusService = new StatusService();
        const key = (fromStatus ? fromStatus.id.toString() : '') + '-' + (toStatus ? toStatus.id.toString() : '');

        return <div style={{display: 'flex', alignItems: 'center'}} key={key}>
            <div style={{flex: 1, paddingRight: '4px'}}>
                {fromStatus ? statusService.renderStatus(fromStatus, fromStatusSelected) : null}
            </div>
            <div style={{flex: 1, paddingLeft: '4px'}}>
                {toStatus ? statusService.renderStatus(toStatus, toStatusSelected) : null}
            </div>
        </div>
    }

    function renderStatuses(reportFull: ReportFull) {
        const fromStatuses = reportFull.fromProgram ? reportFull.fromProgram.statuses : [];
        const toStatuses = reportFull.toProgram ? reportFull.toProgram.statuses : [];
        const maxStatuses = Math.max(fromStatuses.length, toStatuses.length);
        const statuses: ReactElement[] = [];

        for (let i = 0; i < maxStatuses; i++) {
            const fromStatus = i < fromStatuses.length ? fromStatuses[i] : null;
            const fromStatusSelected = Boolean(reportFull.fromStatusId && fromStatus && reportFull.fromStatusId === fromStatus.id);
            const toStatus = i < toStatuses.length ? toStatuses[i] : null;
            const toStatusSelected = Boolean(reportFull.toStatusId && toStatus && reportFull.toStatusId === toStatus.id);

            statuses.push(renderStatusRow(fromStatus, fromStatusSelected, toStatus, toStatusSelected));
        }

        return <>{statuses}</>
    }

    function renderReport(reportFull: ReportFull) {
        const dateService = new DateService();

        const fromCompanyAlias = reportFull.fromProgram ? reportFull.fromProgram.leadCompany.alias : '';
        const fromProgramName = reportFull.fromProgram ? reportFull.fromProgram.name : '';
        const fromStatusName = reportService.getStatusNameFromReportFull(reportFull, true);
        const toCompanyAlias = reportFull.toProgram ? reportFull.toProgram.leadCompany.alias : '';
        const toProgramName = reportFull.toProgram ? reportFull.toProgram.name : '';
        const toStatusName = reportService.getStatusNameFromReportFull(reportFull, false);
        const result = reportService.formatResult(reportFull.result);
        const title = toProgramName + ' Report: ' + result;
        const userName = reportFull.user ? reportFull.user.name : 'Guest';
        const createdAt = dateService.ISOStringToDateDisplayString(reportFull.createdAt);

        return <div>
            <h3>{title}</h3>
            <hr/>
            <div className="row">
                <div className="col-sm-7 col-md-8">
                    <h4>
                        From {fromCompanyAlias ?
                        <Link to={'/company/' + fromCompanyAlias}>{fromProgramName}</Link>
                        : 'Any'} {fromStatusName}
                        <br/>
                        To <Link to={'/company/' + toCompanyAlias}>{toProgramName}</Link> {toStatusName}
                    </h4>
                    <div className="instructions text-break">
                        <Linkify
                            options={{
                                target: {
                                    url: '_blank',
                                    email: null
                                }
                            }}
                        >
                            {reportFull.instructions}
                        </Linkify>
                    </div>
                </div>
                <div className="col-sm-5 col-md-4 mt-3 mt-sm-0">
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1, paddingRight: '3px'}} className="text-center">
                            <strong>
                                {fromCompanyAlias ?
                                    <Link to={'/company/' + fromCompanyAlias}>{fromProgramName}</Link>
                                    : 'Any'}
                            </strong>
                        </div>
                        <div style={{flex: 1, paddingRight: '3px'}} className="text-center">
                            <strong>
                                <Link to={'/company/' + toCompanyAlias}>{toProgramName}</Link>
                            </strong>
                        </div>
                    </div>
                    {renderStatuses(reportFull)}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-7 col-md-8 mt-3">
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                            {(reportFull.user && reportFull.user.avatarFilename) ?
                                <><Link to={'/user/' + reportFull.user.id}>
                                    <img src={'/' + AppConfig.AvatarImagesPath + '/' + reportFull.user.avatarFilename}
                                         alt=""
                                         className="avatar"/>
                                </Link>&nbsp;&nbsp;</>
                                : null
                            }
                        </div>
                        <div className="flex-grow-1 lh-sm">
                            <small>
                                <strong>From:</strong>&nbsp;
                                {reportFull.user ?
                                    <Link to={'/user/' + reportFull.user.id}>{userName}</Link> :
                                    userName} on {createdAt}
                            </small>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5 col-md-4 mt-3 text-end">
                    <Voting
                        voteUpCount={reportFull.voteUpCount}
                        voteDownCount={reportFull.voteDownCount}
                        onVoteUp={(e) => {
                            onVote(e, true)
                        }}
                        onVoteDown={(e) => {
                            onVote(e, false)
                        }}
                        currentVote={reportVoteState.vote}
                        active={votingActive}
                    />
                </div>
            </div>
            <EntityActions<ReportFull>
                small={false}
                entitySchema={ReportSummary.AdminSchema}
                entity={reportFull}
                onPublish={onPublish}
                displayedActions={['publish', 'edit']}
            />
            {reportCommentFullListState.commentFulls.length > 0 &&
            <>
                <hr/>
                <CommentList commentFulls={reportCommentFullListState.commentFulls}
                             onSubmit={onCommentSubmit}
                             onDelete={onCommentDelete}
                />
            </>
            }
            <hr/>
            {(props.currentUserState.userFull && reportFull.id) ?
                <CommentForm userFull={props.currentUserState.userFull}
                             reportId={reportFull.id}
                             onSubmit={onCommentSubmit}
                /> :
                <p>
                    <Link to="/login">Login</Link> or <Link to="/register">Register</Link> to post comments
                </p>
            }
        </div>
    }

    return renderReport(reportFullState.reportFull);
}