import {NotFoundPage} from "../page/NotFoundPage";
import {ReactElement} from "react";
import {UserFull} from "../model/user/UserFull";
import {UserService} from "./entity/UserService";
import {UserFullState} from "../state/cache/UserFullState";
import {useParams} from "react-router";

export interface AuthenticateProps {
    currentUserState: UserFullState;
    allowAdmin: boolean;
    allowSelf: boolean;
    allowAuthenticated: boolean;
    showPageNotFound: boolean;
    children: ReactElement;
}

interface URLParams {
    id: string;
}

export function Authenticate(props: AuthenticateProps) {
    const urlParams = useParams<URLParams>();

    function isAuthorized(currentUserFull: UserFull | null) : boolean {
        const userService = new UserService();

        // Need a logged in user to pass authorization checks
        if (!currentUserFull) {
            return false;
        }

        // Allow authenticated users
        if (props.allowAuthenticated) {
            return true;
        }

        // Allow users with admin role
        if (props.allowAdmin && userService.isAdmin(currentUserFull)) {
            return true;
        }

        // Allow if current user ID matches ID parameter in URL
        if (props.allowSelf && currentUserFull.id === parseInt(urlParams.id))
            return true;

        return false;
    }

    if (!props.currentUserState.loaded) {
        // Current user is not established yet - should be just during initial load
        return null;
    } else if (isAuthorized(props.currentUserState.userFull)) {
        return props.children;
    } else if (props.showPageNotFound) {
        return <NotFoundPage/>;
    } else {
        return null;
    }
}

Authenticate.defaultProps = {
    allowAdmin: false,
    allowSelf: false,
    allowAuthenticated: false,
    showPageNotFound: true
};