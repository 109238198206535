import {EntitySchema} from "./schema/EntitySchema";
import {BaseEntity} from "../model/BaseEntity";
import {Button} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FetchEntityService} from "../service/entity/FetchEntityService";
import {FormService} from "../service/FormService";
import {useHistory} from "react-router-dom";
import {ScrollService} from "../service/ScrollService";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";
import {ConfirmModalContext} from "../context/ConfirmModalContext";

export interface EntityActionsProps<T extends BaseEntity> {
    small: boolean;
    entitySchema: EntitySchema;
    entity: T;
    onPublish?: (entity: T) => void;
    onDelete?: (entity: T) => void;
    displayedActions: string[];
}

export function EntityActions<T extends BaseEntity>(props: EntityActionsProps<T>) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const {setShowConfirmModal, setConfirmModalText, setOnConfirmModalConfirm} = useContext(ConfirmModalContext);
    const history = useHistory();

    // In general limit the display of actions to cases where they might be possible
    // But for now distinguish "can" and "show" for possible changes in the future
    const canPublish = props.entity._metadata.commands.includes('publish') &&
        !props.entity['published' as keyof T];
    const showPublishButton = props.displayedActions.includes('publish') && canPublish;

    const canEdit = props.entity._metadata.commands.includes('edit');
    const showEditButton = props.displayedActions.includes('edit') && canEdit;

    const canDelete = props.entity._metadata.commands.includes('delete');
    const showDeleteButton = props.displayedActions.includes('delete') && canDelete;
    
    function onPublish() {
        if (!props.entity.id) return;

        const fetchEntityService = new FetchEntityService<T>(props.entitySchema.name);
        const formService = new FormService<{}>();

        fetchEntityService.action(props.entity.id, 'publish')
            .then((entityReturned: T) => {
                if (props.onPublish) {
                    props.onPublish(entityReturned);
                }
            })
            .catch((error: Error) => {
                formService.handleSubmitError(error, setCommunicatorState, undefined, {}, false);
            });
    }

    function onEdit() {
        if (!props.entity.id) return;

        history.push('/' + props.entitySchema.name + '/' + props.entity.id + '/edit');
        ScrollService.scrollToTop();
    }

    async function onDeleteConfirm(): Promise<boolean> {
        if (!props.entity.id) return Promise.resolve(false);

        const fetchEntityService = new FetchEntityService<T>(props.entitySchema.name);
        const formService = new FormService<{}>();

        return fetchEntityService.deleteEntity(props.entity.id)
            .then(() => {
                if (props.onDelete) {
                    props.onDelete(props.entity);
                }
                return true;
            })
            .catch((error: Error) => {
                formService.handleSubmitError(error, setCommunicatorState, undefined, {}, false);
                return false;
            });
    }

    function onDelete() {
        setConfirmModalText('Delete ' + props.entitySchema.name + '?');
        setOnConfirmModalConfirm(() => () => onDeleteConfirm());
        setShowConfirmModal(true);
    }

    function renderSmallButtons() {
        return <ButtonGroup size="sm">
            {showPublishButton &&
            <Button title="Publish" disabled={!canPublish} onClick={onPublish}>
                <FontAwesomeIcon icon="book"/>
            </Button>
            }
            {showEditButton &&
            <Button title="Edit" disabled={!canEdit} onClick={onEdit}>
                <FontAwesomeIcon icon="edit"/>
            </Button>
            }
            {showDeleteButton &&
            <Button variant="danger" title="Edit" disabled={!canDelete} onClick={onDelete}>
                <FontAwesomeIcon icon="trash"/>
            </Button>
            }
        </ButtonGroup>
    }

    function renderLargeButtons() {
        return <div className="mt-3">
            {showPublishButton &&
            <Button className="me-3" disabled={!canPublish} onClick={onPublish}>
                <FontAwesomeIcon icon="book"/>&nbsp;Publish
            </Button>
            }
            {showEditButton &&
            <Button disabled={!canEdit} onClick={onEdit}>
                <FontAwesomeIcon icon="edit"/>&nbsp;Edit
            </Button>
            }
            {showDeleteButton &&
            <Button variant="danger" disabled={!canDelete} onClick={onDelete}>
                <FontAwesomeIcon icon="trash"/>&nbsp;Delete
            </Button>
            }
        </div>
    }

    return props.small ? renderSmallButtons() : renderLargeButtons();
}