import * as React from "react";
import {Col, Row} from "react-bootstrap";
import intro1 from "../images/intro1.png";
import intro2 from "../images/intro2.png";
import intro3 from "../images/intro3.png";
import {ReportListState} from "../state/cache/ReportListState";
import {Link, useHistory, useLocation} from "react-router-dom";
import {ListView} from "../component/ListView";
import {ReportSummary} from "../model/report/ReportSummary";
import {PaginationService} from "../service/PaginationService";
import {SimpleReportListItem} from "../businesscomponent/SimpleReportListItem";
import {useEffect} from "react";
import {ScrollService} from "../service/ScrollService";

export interface HomePageProps {
    recentReportState: ReportListState;
}

export function HomePage(props: HomePageProps) {
    const location = useLocation();
    const history = useHistory();
    const page = props.recentReportState.pageMetadata ? props.recentReportState.pageMetadata.number : 0;

    useEffect(() => {
        document.title = 'StatusMatcher | Obtain Elite Travel Status';
    });

    return (
        <div>
            <Row>
                <Col sm={4}>
                    <p className="text-center">
                        <span className="guide-highlight">StatusMatcher</span> helps you obtain the valuable
                        amenities that come with travel loyalty programs
                    </p>
                    <p className="text-center">
                        <img src={intro1} alt=""/>
                    </p>
                </Col>
                <Col sm={4}>
                    <p className="text-center">
                        We share field reports across <Link to="/airlines">Airline</Link>, <Link
                        to="/hotels">Hotel</Link>,
                        and <Link to="/auto">Auto Rental</Link> firms
                    </p>
                    <p className="text-center">
                        <img src={intro2} alt=""/>
                    </p>
                </Col>
                <Col sm={4}>
                    <p className="text-center">
                        Certain travel firms will match your existing loyalty program status with their
                        competitors
                    </p>
                    <p className="text-center">
                        <img src={intro3} alt=""/>
                    </p>
                </Col>
            </Row>
            <hr/>
            <h4 id="recent-report-list" className="mb-3">Recent Status Match Reports</h4>
            <ListView<ReportSummary>
                listItems={props.recentReportState.reportSummaries}
                pageMetadata={props.recentReportState.pageMetadata}
                renderListItem={SimpleReportListItem}
                pluralListItemName="reports"
                showSummary={false}
                onPreviousPage={() => {
                    PaginationService.handlePreviousPage(location, history, page);
                    ScrollService.scrollToElement('recent-report-list');
                }}
                onNextPage={() => {
                    PaginationService.handleNextPage(location, history, page);
                    ScrollService.scrollToElement('recent-report-list');
                }}
            />
        </div>
    );
}