import {useContext, useEffect, useState} from "react";
import {Button, Spinner} from "react-bootstrap";
import {FetchEntityService} from "../service/entity/FetchEntityService";
import {FormService} from "../service/FormService";
import {FetchErrorService} from "../service/error/FetchErrorService";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";
import {CommunicatorState} from "../state/ui/CommunicatorState";

export function GeneralAdminPage() {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const [clearCacheSubmitting, setClearCacheSubmitting] = useState(false);
    const [generateSummaryDataSubmitting, setGenerateSummaryDataSubmitting] = useState(false);

    const fetchEntityService = new FetchEntityService<{}>('admin');
    const fetchErrorService = new FetchErrorService();
    const formService = new FormService<{}>();

    useEffect(() => {
        document.title = 'General Admin | StatusMatcher';
    });

    async function adminFunction(endpoint: string): Promise<void> {
        const url = fetchEntityService.baseURL + endpoint;
        const headers = new Headers({});
        fetchEntityService.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            headers
        });

        await fetchErrorService.checkResponseForError(response);
    }

    function onClearCache() {
        setClearCacheSubmitting(true);
        adminFunction('/cache/clear')
            .then(() => {
                setClearCacheSubmitting(false);
                setCommunicatorState(new CommunicatorState('Cache cleared',
                    'success',
                    true,
                    true));
            })
            .catch((error: Error) => {
                setClearCacheSubmitting(false);
                formService.handleSubmitError(error, setCommunicatorState, undefined, {}, false);
            })
    }

    function onGenerateSummaryData() {
        setGenerateSummaryDataSubmitting(true);
        adminFunction('/summary-data/generate')
            .then(() => {
                setGenerateSummaryDataSubmitting(false);
                setCommunicatorState(new CommunicatorState('Summary data generated',
                    'success',
                    true,
                    true));
            })
            .catch((error: Error) => {
                setGenerateSummaryDataSubmitting(false);
                formService.handleSubmitError(error, setCommunicatorState, undefined, {}, false);
            })
    }

    return (
        <div>
            <h3>General Admin</h3>
            <hr/>
            <div>
                <Button onClick={onClearCache} disabled={clearCacheSubmitting}>
                    Clear Cache
                    {
                        clearCacheSubmitting &&
                        <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                    }
                </Button>
            </div>
            <hr/>
            <div>
                <Button onClick={onGenerateSummaryData} disabled={generateSummaryDataSubmitting}>
                    Generate Summary Data
                    {
                        generateSummaryDataSubmitting &&
                        <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                    }
                </Button>
            </div>
        </div>
    );
}