import {Status} from "../../model/program/Status";
import tinycolor from "tinycolor2";


export class StatusService {
    public renderStatus(status: Status, selected: boolean, span: boolean = false) {
        const backgroundColor = tinycolor(status.color);
        const borderColor = backgroundColor.clone().darken();
        const color = backgroundColor.isDark() ? tinycolor('white') : tinycolor('black');

        if (span) {
            return <span className={'status-box' + (selected ? ' selected' : '')}
                         key={status.id}
                         style={{
                             color: color.toHexString(),
                             backgroundColor: backgroundColor.toHexString(),
                             borderColor: borderColor.toHexString()
                         }}>
                {status.name}
            </span>
        } else {
            return <div className={'status-box-container' + (selected ? ' selected' : '')} key={status.id}>
                <div className="status-box"
                     style={{
                         color: color.toHexString(),
                         backgroundColor: backgroundColor.toHexString(),
                         borderColor: borderColor.toHexString()
                     }}>
                    {status.name}
                </div>
            </div>
        }
    }
}