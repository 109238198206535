import {UserFull} from "../model/user/UserFull";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {Link} from "react-router-dom";
import {FormTextarea} from "../component/form/FormTextarea";
import {Button, Spinner} from "react-bootstrap";
import {CommentService} from "../service/entity/CommentService";
import {FormService} from "../service/FormService";
import {Comment} from "../model/comment/Comment";
import {ScrollService} from "../service/ScrollService";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface CommentFormProps {
    userFull: UserFull;
    reportId: number;
    onSubmit: (comment: Comment) => void;
}

interface CommentFormValues {
    body: string;
}

export function CommentForm(props: CommentFormProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const initialValues: CommentFormValues = {
        body: ''
    };

    function handleSubmit(commentFormValues: CommentFormValues, formikHelpers: FormikHelpers<CommentFormValues>) {
        if (!props.userFull.id) return;

        const commentService = new CommentService();
        const formService = new FormService<CommentFormValues>();

        const comment = {
            id: null,
            body: commentFormValues.body,
            userId: props.userFull.id,
            parentId: null,
            reportId: props.reportId,
            read: false,
            deleted: false
        };

        commentService.newEntity(comment)
            .then((commentReturned: Comment) => {
                formikHelpers.setSubmitting(false);
                formikHelpers.resetForm();
                props.onSubmit(commentReturned);
            })
            .catch((error: Error) => {
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    commentFormValues,
                    false);
                ScrollService.scrollToTop();
            });
    }

    return <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
            body: Yup.string().max(CommentService.MaxSize, 'Too large')
        })}
        onSubmit={handleSubmit}
    >
        {formik => <Form noValidate={true} className="row g-3">
            <div className="col-12">
                <label htmlFor="yourName" className="form-label">Your Name</label>
                <div>
                    <Link to={'/user/' + props.userFull.id}>{props.userFull.name}</Link>
                </div>
            </div>
            <div className="col-12">
                <FormTextarea
                    label="Comment"
                    name="body"
                    rows={4}
                />
            </div>
            <div className="col-12">
                <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                    Add Comment
                    {
                        formik.isSubmitting &&
                        <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                    }
                </Button>
            </div>
        </Form>}
    </Formik>
}