import {CacheStateService} from "./CacheStateService";
import {EntityState} from "../../../state/cache/EntityState";
import {FetchEntityService} from "../../entity/FetchEntityService";

interface EntityStateRefreshData {
    id: number;
}

export class EntityStateService<T> extends CacheStateService<EntityState<T>, EntityStateRefreshData> {
    constructor(private readonly modelName: string,
                private readonly view: string = '') {
        super();
    }

    protected isRefreshNeededStepTwo(refreshData: EntityStateRefreshData, entityState: EntityState<T>) {
        return (refreshData.id !== entityState.id);
    }

    protected async getNewStatePromise(refreshData: EntityStateRefreshData, entityState: EntityState<T>): Promise<EntityState<T>> {
        if (refreshData.id) {
            const fetchEntityService = new FetchEntityService<T>(this.modelName);
            const entity = this.view ?
                await fetchEntityService.getOneView<T>(refreshData.id, this.view) :
                await fetchEntityService.getOne(refreshData.id);

            return new EntityState<T>(refreshData.id, entity, false, true);
        } else {
            return new EntityState<T>(undefined, null, false, false);
        }
    }
}