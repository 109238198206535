import {CacheStateService} from "./CacheStateService";
import {ProgramService} from "../../entity/ProgramService";
import {ProgramAndStatusesState} from "../../../state/cache/ProgramAndStatusesState";
import {ProgramAndStatuses} from "../../../model/program/ProgramAndStatuses";

export class ProgramAndStatusesStateService extends CacheStateService<ProgramAndStatusesState, {}> {
    protected async getNewStatePromise(refreshData: object, programAndStatusesState: ProgramAndStatusesState): Promise<ProgramAndStatusesState> {
        const programService = new ProgramService();

        // Get an array of objects which have program and status details
        const programAndStatuses: ProgramAndStatuses[] = await programService.getAllViews<ProgramAndStatuses>('programAndStatuses');

        // Convert this into a JavaScript map for efficient lookups
        // Note: JSON and the default parsing function in JavaScript work with objects
        // so to get a JavaScript Map, this conversion step is necessary
        const programAndStatusMap = new Map<number, ProgramAndStatuses>();
        programAndStatuses.forEach(p => programAndStatusMap.set(p.id, p));

        return new ProgramAndStatusesState(programAndStatusMap, false, true);
    }
}