import {Result} from "./Result";
import {EntityMetadata} from "../EntityMetadata";
import {ProgramFull} from "../program/ProgramFull";
import {User} from "../user/User";
import {BaseEntity} from "../BaseEntity";

export class ReportFull implements BaseEntity {
    id: number | null = null;
    _metadata: EntityMetadata = new EntityMetadata();
    instructions: string = '';
    result: Result = Result.MATCH;
    published: boolean = false;
    read: boolean = false;
    fromProgram: ProgramFull | null = null;
    fromStatusId: number | null = null;
    toProgram: ProgramFull | null = null;
    toStatusId: number | null = null;
    user: User | null = null;
    voteUpCount: number = 0;
    voteDownCount: number = 0;
    createdAt: string | null = null;
    updatedAt: string | null = null;
}