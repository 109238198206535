import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {UserRegistrationForm} from "../../businesscomponent/UserRegistrationForm";
import {useHistory} from "react-router";
import {useContext, useEffect} from "react";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";

export function UserRegistrationPage() {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const history = useHistory();

    useEffect(() => {
        document.title = 'Register | StatusMatcher';
    });

    function onSubmit(email: string) {
        history.push('/');
        setCommunicatorState(new CommunicatorState(
            'Your user registration has been received! An email has been sent to ' + email + ' with instructions for completing the registration process.',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
    }

    return (
        <div>
            <h3>Register</h3>
            <hr/>
            <UserRegistrationForm
                onSubmit={onSubmit}
            />
        </div>
    );
}