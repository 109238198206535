import {default as React, useEffect} from "react";
import {CompanySummaryState} from "../state/cache/CompanySummaryState";
import {Tab, Tabs} from "react-bootstrap";
import {CompanyService} from "../service/entity/CompanyService";

export interface HotelPageProps {
    companySummaryState: CompanySummaryState;
}

export function HotelPage(props: HotelPageProps) {
    useEffect(() => {
        document.title = 'Browse Hotels | StatusMatcher';
    });

    const companyService = new CompanyService();

    return (
        <div>
            <h3>Browse Hotels</h3>
            <Tabs defaultActiveKey="byName" className="mt-3">
                <Tab eventKey="byName" title="By Name">
                    <div className="row">
                        <div className="col-md-4">
                            {companyService.renderFirstLetterBlocks('A', 'G', props.companySummaryState.hotelFirstLetterToCompanySummaryMap)}
                        </div>
                        <div className="col-md-4">
                            {companyService.renderFirstLetterBlocks('H', 'O', props.companySummaryState.hotelFirstLetterToCompanySummaryMap)}
                        </div>
                        <div className="col-md-4">
                            {companyService.renderFirstLetterBlocks('P', 'Z', props.companySummaryState.hotelFirstLetterToCompanySummaryMap)}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <span className="badge rounded-pill">M</span> <i>indicates likely to match</i><br/>
                            <span className="badge rounded-pill">C</span> <i>indicates likely to offer a challenge</i>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="byMatchRate" title="By Match Rate">
                    <table className="table mt-3">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Match Rate</th>
                        </tr>
                        </thead>
                        <tbody>
                            {companyService.renderCompanySummaryTableRows(props.companySummaryState.hotelCompanySummariesByMatchRate)}
                        </tbody>
                    </table>
                </Tab>
            </Tabs>
        </div>
    );
}