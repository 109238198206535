import {useEffect} from "react";
import {ScrollService} from "../service/ScrollService";

export function TermsPage() {
        useEffect(() => {
                document.title = 'Terms and Conditions | StatusMatcher';
                ScrollService.scrollToTop();
        });

        return (
        <div>
            <h3>Terms and Conditions</h3>
            <hr/>
            <h4>Copyright</h4>
            <p>The website StatusMatcher.com is owned by Statusmatcher LLC, Conshohocken, PA, USA. All content on StatusMatcher.com is copyright Statusmatcher LLC, with all rights reserved. Images, text, code, and formatting from this site may not be reused in any way without the written consent of Statusmatcher LLC.</p>

            <h4>Site Usage</h4>
            <p>StatusMatcher.com is provided to you on condition that you abide by all the terms and conditions listed here. By using the site in any manner, you agree to the terms listed here. If you do not accept all of the terms listed here, please do not use the website.</p>
            <p>Our website is not intended for children under 18 years of age. We do not knowingly collect personal information from children under 18. If you are under 18, please do not provide any information on this website.</p>

            <h4>User Submissions</h4>
            <p>By submitting content to StatusMatcher.com, via email, web form, or other method, you agree to allow Statusmatcher LLC to use this content in any way. This may include translating, reducing, paraphrasing, publishing, or creating derivative works based on this content. This content may or may not be attributed to you based on the name provided by you. Statusmatcher LLC takes no responsibility and assumes no liability for the content of user submissions.</p>

            <h4>Privacy Policy</h4>
            <p>In providing its service to you, StatusMatcher.com collects various data including personal information. This includes the direct user submissions mentioned above: status matching reports, comments, messages, and votes on existing reports. This allows us to provide useful website content itself, which is based on users' sharing of data. For users that have created an account, we store user-specific data such as name, login, password details, avatar image, and email address. This allows us to indicate which users have provided which content, and also to communicate with specific users as needed (primarily transactional emails required for proper site functionality). For all site visitors (those with an account or not), we track basic web metrics, such as IP address used, HTTP requests made, and timing of those requests. This is used for our understanding of the volume and nature of site visitors, so that we can build the site with suitable capacity and scalability.</p>

            <p>We use cookies to track activity on StatusMatcher.com. Cookies allow a web server to store information on a individual user's web browser. If desired, cookies can be disabled by a user in the settings of your web browser. We use these cookies to provide basic site functionality, in particular: a) to track a user's web browsing session, which enables a logged-in user to stay logged in while navigating between different web pages within the site and b) as a security measure related to form submissions.</p>

            <p>The data collected by StatusMatcher is stored on the servers of our hosting provider DigitalOcean. These are secured via a firewall and only accessed via secure channels such as SSH.</p>

            <p>For any questions or requests related to personal data please email: admin@statusmatcher.com. This may include a) requesting a copy of all data we store related to yourself, b) requesting update or deletion of data we store related to yourself or c) any other privacy and personal data related questions.</p>

            <h4>Warranties</h4>
            <p>The content on StatusMatcher.com is provided without any warranty whatsoever. Statusmatcher LLC provides no guarantees as to the accuracy or timeliness of data shown on StatusMatcher.com. As loyalty program policies may change at any time and for no particular reason, users of StatusMatcher.com should understand that any particular reports or other information found on the site may no longer be valid.</p>

        </div>
    );
}