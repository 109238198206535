import {Form, Formik, FormikHelpers, useFormikContext} from "formik";
import {FormTextarea} from "../component/form/FormTextarea";
import * as Yup from 'yup';
import {Button, Spinner} from "react-bootstrap";
import {FormSelect} from "../component/form/FormSelect";
import {Report} from "../model/report/Report";
import {ReportService} from "../service/entity/ReportService";
import {CommunicatorState} from "../state/ui/CommunicatorState";
import {FormService} from "../service/FormService";
import {ProgramAndStatuses} from "../model/program/ProgramAndStatuses";
import {useEffect} from "react";
import {ReportFull} from "../model/report/ReportFull";
import {FormInput} from "../component/form/FormInput";
import {FormCheckbox} from "../component/form/FormCheckbox";
import {UserFull} from "../model/user/UserFull";
import {Link} from "react-router-dom";

// Local StatusSelect component only used in this form
interface StatusSelectProps {
    programAndStatusesMap: Map<number, ProgramAndStatuses>;
    label: string;
    name: string;
}

function StatusSelect(props: StatusSelectProps) {
    const {
        values: {fromProgramId, fromStatusId, toProgramId, toStatusId},
        setFieldValue
    } = useFormikContext<ReportFormValues>();

    const isFrom = props.name === 'fromStatusId';
    let statusOptions = null;
    let programAndStatuses: ProgramAndStatuses | undefined = undefined;

    // Set status options based on selected program
    const programIdNumber = parseInt(isFrom ? fromProgramId : toProgramId);
    if (!isNaN(programIdNumber) && programIdNumber > 0) {
        programAndStatuses = props.programAndStatusesMap.get(programIdNumber);
        if (programAndStatuses) {
            // Note: option value must be a string
            statusOptions = programAndStatuses.statuses.map(status =>
                <option value={status.id.toString()} key={status.id}>
                    {status.name}
                </option>
            );
        }
    }

    // Check status value, clear out if no longer a valid option
    useEffect(() => {
        if (programAndStatuses) {
            if (isFrom) {
                const fromStatusIdNumber = parseInt(fromStatusId);
                if (!isNaN(fromStatusIdNumber) && fromStatusIdNumber > 0) {
                    if (!programAndStatuses.statuses.some(s => s.id === fromStatusIdNumber)) {
                        setFieldValue('fromStatusId', '');
                    }
                }
            } else {
                const toStatusIdNumber = parseInt(toStatusId);
                if (!isNaN(toStatusIdNumber) && toStatusIdNumber > 0) {
                    if (!programAndStatuses.statuses.some(s => s.id === toStatusIdNumber)) {
                        setFieldValue('toStatusId', '');
                    }
                }
            }
        }
    }, [fromProgramId, fromStatusId, toProgramId, toStatusId, isFrom, programAndStatuses, setFieldValue]);

    return (
        <FormSelect label={props.label} name={props.name}>
            <option value={undefined}>(Any)</option>
            {statusOptions}
        </FormSelect>
    );
}

export interface ReportFormProps {
    edit: boolean;
    reportFull?: ReportFull;
    programAndStatusesMap: Map<number, ProgramAndStatuses>;
    onSubmit?: (report: Report) => void;
    updateCommunicatorState: (communicatorState: CommunicatorState) => void;
    currentUserFull: UserFull | null;
}

interface ReportFormValues {
    // Note: it seems that the value of an <option> in an HTML form must be a string
    fromProgramId: string;
    fromStatusId: string;
    toProgramId: string;
    toStatusId: string;
    instructions: string;
    result: string;
    userId: string;
    published: boolean;
}

export function ReportForm(props: ReportFormProps) {
    const reportFull = props.reportFull ? props.reportFull : new ReportFull();
    const initialValues: ReportFormValues = {
        fromProgramId: reportFull.fromProgram ? reportFull.fromProgram.id.toString() : '',
        fromStatusId: reportFull.fromStatusId ? reportFull.fromStatusId.toString() : '',
        toProgramId: reportFull.toProgram ? reportFull.toProgram.id.toString() : '',
        toStatusId: reportFull.toStatusId ? reportFull.toStatusId.toString() : '',
        instructions: reportFull.instructions,
        result: reportFull.result,
        userId: (reportFull.user && reportFull.user.id) ? reportFull.user.id.toString() : '',
        published: reportFull.published
    };

    function handleSubmit(reportFormValues: ReportFormValues, formikHelpers: FormikHelpers<ReportFormValues>) {
        const reportService = new ReportService();
        const formService = new FormService<ReportFormValues>();

        const report = new Report();
        report.fromProgramId = formService.idFromString(reportFormValues.fromProgramId);
        report.fromStatusId = formService.idFromString(reportFormValues.fromStatusId);
        report.toProgramId = formService.idFromString(reportFormValues.toProgramId);
        report.toStatusId = formService.idFromString(reportFormValues.toStatusId);
        report.instructions = reportFormValues.instructions;
        report.result = reportService.resultFromString(reportFormValues.result);

        let submitPromise: Promise<Report>;
        if (props.edit && reportFull.id) {
            report.userId = formService.idFromString(reportFormValues.userId);
            report.published = reportFormValues.published;
            submitPromise = reportService.update(reportFull.id, report);
        } else {
            submitPromise = reportService.newEntity(report);
        }

        submitPromise
            .then((reportReturned: Report) => {
                formikHelpers.setSubmitting(false);
                if (props.onSubmit) {
                    props.onSubmit(reportReturned);
                }
            })
            .catch((error: Error) => {
                formService.handleSubmitError(error,
                    props.updateCommunicatorState,
                    formikHelpers,
                    reportFormValues,
                    false);
            });
    }

    const programOptions: React.ReactNode[] = [];
    props.programAndStatusesMap.forEach((programAndStatuses: ProgramAndStatuses) => {
        // Note: value must be a string
        programOptions.push(
            <option value={programAndStatuses.id.toString()} key={programAndStatuses.id}>
                {programAndStatuses.name}
            </option>
        );
    });

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
                instructions: Yup.string().required('Required').max(ReportService.MaxSize, 'Too large'),
                result: Yup.string().required('Required'),
                toProgramId: Yup.string().required('Required')
            })}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form noValidate={true} className="row g-3">
                    <div className="col-12">
                        <label htmlFor="yourName" className="form-label">Your Name</label>
                        <div>
                        {
                            props.currentUserFull ?
                                <Link to={'/user/' + props.currentUserFull.id}>{props.currentUserFull.name}</Link> :
                                <>Guest (<Link to="/login">Login</Link> or <Link to="/register">Register</Link>)</>
                        }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <FormSelect label="From Program" name="fromProgramId">
                            <option value="">(Any)</option>
                            {programOptions}
                        </FormSelect>
                    </div>
                    <div className="col-md-6">
                        <FormSelect label="To Program *" name="toProgramId">
                            <option value="">(Any)</option>
                            {programOptions}
                        </FormSelect>
                    </div>
                    <div className="col-md-6">
                        <StatusSelect programAndStatusesMap={props.programAndStatusesMap}
                                      label="From Status"
                                      name="fromStatusId"/>
                    </div>
                    <div className="col-md-6">
                        <StatusSelect programAndStatusesMap={props.programAndStatusesMap}
                                      label="To Status"
                                      name="toStatusId"/>
                    </div>
                    <div className="col-12">
                        <FormTextarea
                            label="Instructions *"
                            name="instructions"
                            formText="Please include how you contacted the firm (email address, phone number, ...etc) and what actions you took. Did you have to send proof of your existing status?"
                        />
                    </div>
                    <div className="col-md-6">
                        <FormSelect label="Result *" name="result">
                            <option value="MATCH">Match</option>
                            <option value="DENY">Deny</option>
                            <option value="CHALLENGE">Challenge</option>
                            <option value="OTHER">Other</option>
                        </FormSelect>
                    </div>
                    {props.edit &&
                    <>
                        <div className="col-md-6">
                            <FormInput label="User ID" name="userId"/>
                        </div>
                        <div className="col-12">
                            <FormCheckbox label="Published" name="published"/>
                        </div>
                    </>
                    }
                    <div className="col-12">
                        <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                            {props.edit ? 'Save Report' : 'Create Report'}
                            {
                                formik.isSubmitting &&
                                <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                            }
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}