import {useHistory, useLocation, useParams} from "react-router";
import {default as React, useContext, useEffect, useState} from "react";
import {UserState} from "../../state/cache/UserState";
import {UserStateService} from "../../service/state/cache/UserStateService";
import {DateService} from "../../service/DateService";
import {User} from "../../model/user/User";
import {AppConfig} from "../../AppConfig";
import {UserReportListStateService} from "../../service/state/cache/UserReportListStateService";
import {PaginationService} from "../../service/PaginationService";
import {ListView} from "../../component/ListView";
import {ReportSummary} from "../../model/report/ReportSummary";
import {ReportListItem} from "../../businesscomponent/ReportListItem";
import {UserReportListState} from "../../state/cache/UserReportListState";
import {UserFullState} from "../../state/cache/UserFullState";
import {UserFull} from "../../model/user/UserFull";
import {FetchErrorService} from "../../service/error/FetchErrorService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";
import {EntityActions} from "../../component/EntityActions";

export interface UserPageProps {
    currentUserState: UserFullState;
}

interface UserPageRouteParams {
    id: string;
}

export function UserPage(props: UserPageProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const location = useLocation();
    const history = useHistory();
    const userPageRouteParams = useParams<UserPageRouteParams>();
    const [userState, setUserState] = useState(new UserState());
    const [userReportListState, setUserReportListState] = useState(new UserReportListState());

    useEffect(() => {
        if (userState.user && userState.user.name) {
            document.title = userState.user.name + ' | StatusMatcher';
        } else {
            document.title = 'User | StatusMatcher';
        }
    });

    function doEffects() {
        if (userPageRouteParams.id) {
            const userId: number = parseInt(userPageRouteParams.id);
            (new UserStateService()).refresh({id: userId}, userState, setUserState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'user');
                });

            (new UserReportListStateService(userId)).refresh({
                    userId,
                    page: PaginationService.getPageFromLocation(location)
                },
                userReportListState,
                setUserReportListState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'report');
                });
        }
    }

    useEffect(doEffects);

    const page = userReportListState.pageMetadata ? userReportListState.pageMetadata.number : 0;

    function RenderBasicUserData(user: User, currentUserFull: UserFull | null) {
        const dateService = new DateService();
        const createdAtDisplayString = dateService.ISOStringToDateDisplayString(user.createdAt);
        const userIsCurrentUser = (currentUserFull && currentUserFull.id === user.id);

        return <>
            <h3>{user.name}</h3>
            <hr/>
            <p>
                {user.avatarFilename &&
                <><img src={'/' + AppConfig.AvatarImagesPath + '/' + user.avatarFilename} alt=""
                       className="avatar"/>&nbsp;&nbsp;</>
                }
                {userIsCurrentUser ?
                    <>You have </>
                    : <>User <strong>{user.name}</strong> has </>
                }
                been a member since <i>{createdAtDisplayString}</i>
            </p>
            <EntityActions<User>
                small={false}
                entitySchema={UserFull.AdminSchema}
                entity={user}
                displayedActions={['edit']}
            />
        </>;
    }

    function RenderReports() {
        return <>
            <hr/>
            <h4 className="mb-3">Reports</h4>
            {userReportListState.reportSummaries.length > 0 ?
                <ListView<ReportSummary>
                    listItems={userReportListState.reportSummaries}
                    pageMetadata={userReportListState.pageMetadata}
                    renderListItem={ReportListItem}
                    pluralListItemName="reports"
                    showSummary={false}
                    onPreviousPage={() => PaginationService.handlePreviousPage(location, history, page)}
                    onNextPage={() => PaginationService.handleNextPage(location, history, page)}
                /> :
                'No published reports'
            }
        </>;
    }

    // Handle case where user is not loaded
    if (!userState.user) return null;

    return (
        <div>
            {RenderBasicUserData(userState.user, props.currentUserState.userFull)}
            {RenderReports()}
        </div>
    );
}