export class Vote {
    constructor(reportId: number, up: boolean) {
        this.reportId = reportId;
        this.up = up;
    }

    id: number | null = null;
    userId: number | null = null;
    reportId: number | null = null;
    up: boolean = false;
}