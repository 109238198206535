import {EntityMetadata} from "../EntityMetadata";

export class Message {
    id: number | null = null;
    _metadata: EntityMetadata = new EntityMetadata();
    userId: number | null = null;
    name: string = '';
    email: string = '';
    subject: string = '';
    message: string = '';
    read: boolean = false;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    constructor(name: string,
                email: string,
                subject: string,
                message: string) {
        this.name = name;
        this.email = email;
        this.subject = subject;
        this.message = message;
    }
}