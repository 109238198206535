import {CommunicatorState} from "../state/ui/CommunicatorState";
import * as React from "react";
import {Alert} from "react-bootstrap";

export interface CommunicatorProps {
    communicatorState: CommunicatorState;
    onClose: () => void;
}

export function Communicator(props: CommunicatorProps) {
    return (
        <Alert variant={props.communicatorState.variant}
               show={props.communicatorState.visible}
               dismissible={true}
               onClose={props.onClose}
               transition={false}>
            {props.communicatorState.message}
        </Alert>
    );
}