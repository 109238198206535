import {default as React, useEffect} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {CompanySummaryState} from "../state/cache/CompanySummaryState";
import {CompanyService} from "../service/entity/CompanyService";

export interface AirlinePageProps {
    companySummaryState: CompanySummaryState;
}

export function AirlinePage(props: AirlinePageProps) {
    useEffect(() => {
        document.title = 'Browse Airlines | StatusMatcher';
    });

    const companyService = new CompanyService();

    return (
        <div>
            <h3>Browse Airlines</h3>
            <Tabs defaultActiveKey="byName" className="mt-3">
                <Tab eventKey="byName" title="By Name">
                    <div className="row">
                        <div className="col-md-4">
                            {companyService.renderFirstLetterBlocks('A', 'D', props.companySummaryState.airlineFirstLetterToCompanySummaryMap)}
                        </div>
                        <div className="col-md-4">
                            {companyService.renderFirstLetterBlocks('E', 'M', props.companySummaryState.airlineFirstLetterToCompanySummaryMap)}
                        </div>
                        <div className="col-md-4">
                            {companyService.renderFirstLetterBlocks('N', 'Z', props.companySummaryState.airlineFirstLetterToCompanySummaryMap)}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <span className="badge rounded-pill">M</span> <i>indicates likely to match</i><br/>
                            <span className="badge rounded-pill">C</span> <i>indicates likely to offer a challenge</i>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="byMatchRate" title="By Match Rate">
                    <table className="table mt-3">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Match Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companyService.renderCompanySummaryTableRows(props.companySummaryState.airlineCompanySummariesByMatchRate)}
                        </tbody>
                    </table>
                </Tab>
            </Tabs>
        </div>
    );
}