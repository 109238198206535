import {HttpProblemDetails} from "../../model/error/HttpProblemDetails";
import {ConstraintProblem} from "../../model/error/ConstraintProblem";
import {HttpStatus} from "./HttpStatus";
import {HttpProblemType} from "./HttpProblemType";
import {HttpProblemDetailsError} from "./HttpProblemDetailsError";
import {AuthenticationError} from "./AuthenticationError";
import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {NotFoundError} from "./NotFoundError";

export class FetchErrorService {
    // Runtime check of type of HTTP problem details object
    public isConstraintProblem(httpProblemDetails: HttpProblemDetails): httpProblemDetails is ConstraintProblem {
        return (httpProblemDetails.type !== undefined
            && httpProblemDetails.type === HttpProblemType.CONSTRAINT_VIOLATION
            && (httpProblemDetails as ConstraintProblem).constraintViolations !== undefined
        );
    }

    // Checks response for error and throws appropriate exception
    async checkResponseForError(response: Response) {
        // 2xx ok
        if (response.ok) return;

        // 400 bad request
        if (response.status === HttpStatus.BAD_REQUEST) {
            const contentType: (string | null) = response.headers.get('content-type');

            // Content-type: application/problem+json
            // Get further error details from the response body
            if (contentType && contentType.includes(HttpProblemType.CONTENT_TYPE)) {
                const httpProblemDetails: HttpProblemDetails = await response.json();
                throw new HttpProblemDetailsError(httpProblemDetails, response.statusText);
            }
        }

        // 401 unauthorized
        if (response.status === HttpStatus.UNAUTHORIZED) {
            throw new AuthenticationError(response.statusText);
        }

        // 404 not found
        if (response.status === HttpStatus.NOT_FOUND) {
            throw new NotFoundError(response.statusText);
        }

        // Any other error code
        throw new Error(response.statusText);
    }

    handlePageLoadError(error: Error,
                      setCommunicatorState: (communicatorState: CommunicatorState) => void,
                      recordType: String = 'record') {
        let communicatorMessage = 'An error occurred while loading the page';
        if (error instanceof AuthenticationError) {
            communicatorMessage = 'An authentication error occurred while loading the page';
        }
        if (error instanceof NotFoundError) {
            communicatorMessage = 'The selected ' + recordType + ' was not found';
        }
        setCommunicatorState(new CommunicatorState(communicatorMessage, 'danger', true));
    }
}