import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {FormTextarea} from "../component/form/FormTextarea";
import {CommentService} from "../service/entity/CommentService";
import {FormService} from "../service/FormService";
import {Comment} from "../model/comment/Comment";
import {CommentFull} from "../model/comment/CommentFull";
import {default as React, SyntheticEvent, useContext} from "react";
import {User} from "../model/user/User";
import {ScrollService} from "../service/ScrollService";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface CommentFormInlineProps {
    edit: boolean;
    commentFull: CommentFull;
    user: User;
    onSubmit: (comment: Comment) => void;
    onCancel: () => void;
}

interface CommentFormInlineValues {
    body: string;
}

export function CommentFormInline(props: CommentFormInlineProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const initialValues: CommentFormInlineValues = {
        body: props.edit ? props.commentFull.body : ''
    };

    function onCancel(e: SyntheticEvent) {
        e.preventDefault();
        props.onCancel();
    }

    function handleSubmit(commentFormInlineValues: CommentFormInlineValues, formikHelpers: FormikHelpers<CommentFormInlineValues>) {
        if (!props.user.id) return;
        if (!(props.commentFull.report && props.commentFull.report.id)) return;

        const commentService = new CommentService();
        const formService = new FormService<CommentFormInlineValues>();

        const comment = {
            id: props.edit ? props.commentFull.id : null,
            body: commentFormInlineValues.body,
            userId: props.user.id,
            parentId: props.edit ? props.commentFull.parentId : props.commentFull.id,
            reportId: props.commentFull.report.id,
            read: false,
            deleted: false
        };

        let submitPromise: Promise<Comment>;
        if (props.edit && props.commentFull.id) {
            submitPromise = commentService.update(props.commentFull.id, comment);
        } else {
            submitPromise = commentService.newEntity(comment);
        }

        submitPromise
            .then((commentReturned: Comment) => {
                formikHelpers.setSubmitting(false);
                formikHelpers.resetForm();
                props.onSubmit(commentReturned);
            })
            .catch((error: Error) => {
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    commentFormInlineValues,
                    false);
                ScrollService.scrollToTop();
            });
    }

    return <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
            body: Yup.string().max(CommentService.MaxSize, 'Too large')
        })}
        onSubmit={handleSubmit}
    >
        {formik => <Form noValidate={true} className="row g-3">
            <div className="col-12">
                {!props.edit &&
                <small><i>Your Reply:</i></small>
                }
                <FormTextarea
                    label=""
                    name="body"
                    rows={4}
                />
            </div>
            <p className="text-end">
                <a href="#save"
                   className="inline-command"
                   onClick={(e) => {
                       e.preventDefault();
                       if (!formik.isSubmitting && formik.isValid && formik.dirty) {
                           formik.submitForm();
                       }
                   }}>
                    Save
                </a>
                <a href="#cancel" className="inline-command" onClick={onCancel}>Cancel</a>
            </p>
        </Form>}
    </Formik>
}