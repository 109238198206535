import {CacheStateService} from "./CacheStateService";
import {PagedModel} from "../../../model/PagedModel";
import {ReportSummary} from "../../../model/report/ReportSummary";
import {ReportService} from "../../entity/ReportService";
import {PaginationService} from "../../PaginationService";
import {UserReportListState} from "../../../state/cache/UserReportListState";

interface UserReportListStateRefreshData {
    userId: number;
    page: number;
}

export class UserReportListStateService extends CacheStateService<UserReportListState, UserReportListStateRefreshData> {
    private static readonly View = 'userReportList';

    constructor(private userId: number) {
        super();
    }

    protected isRefreshNeededStepTwo(refreshData: UserReportListStateRefreshData, userReportListState: UserReportListState) {
        return (
            refreshData.userId !== userReportListState.userId ||
            refreshData.page !== userReportListState.pageMetadata.number
        );
    }

    protected async getNewStatePromise(refreshData: UserReportListStateRefreshData, userReportListState: UserReportListState): Promise<UserReportListState> {
        const reportService = new ReportService();

        const reportSummaryPagedModel: PagedModel<ReportSummary> = await reportService.getPageViewsByUser<ReportSummary>(
            refreshData.page,
            PaginationService.DefaultPageSize,
            UserReportListStateService.View,
            this.userId);

        // Handle case of no data returned
        const reportSummaries: ReportSummary[] = reportSummaryPagedModel.collection
            ? reportSummaryPagedModel.collection
            : [];

        return new UserReportListState(this.userId, reportSummaries, reportSummaryPagedModel._metadata, false, true);
    }
}