import {CacheState} from "./CacheState";

/*
    Simple state that just stores a number
    Does not store details on how the number was obtained (associated entity ID, URL used, ...etc)
    Would need a more advanced state to track that
 */
export class NumberState implements CacheState {
    constructor(public value: number | null = null,
                public pendingRequest: boolean = false,
                public loaded: boolean = false) {}
}