import {FetchEntityService} from "./FetchEntityService";
import {User} from "../../model/user/User";
import {HttpStatus} from "../error/HttpStatus";
import {UserFull} from "../../model/user/UserFull";
import {UserRegistration} from "../../model/user/UserRegistration";
import {UserRegistrationToken} from "../../model/user/UserRegistrationToken";
import {ChangePassword} from "../../model/user/ChangePassword";
import {ResetPassword} from "../../model/user/ResetPassword";
import {ResetPasswordToken} from "../../model/user/ResetPasswordToken";

export class UserService extends FetchEntityService<User> {
    constructor() {
        super('user');
    }

    async getCurrent(): Promise<UserFull | null> {
        const url = this.baseURL + '/current';
        const response = await fetch(url);

        if (response.status === HttpStatus.NO_CONTENT) {
            return Promise.resolve(null);
        } else {
            await this.fetchErrorService.checkResponseForError(response);
            return await response.json();
        }
    }

    async login(email: string, password: string, rememberMe: boolean): Promise<UserFull> {
        const url = this.baseURL + '/current' + (rememberMe ? '?remember-me=true' : '');
        const response = await fetch(url, {
            headers: {
                'Authorization': 'Basic ' + btoa(email + ':' + password),
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async logout(): Promise<Response> {
        const url = '/api/logout';
        const headers = new Headers();
        this.appendCsrfTokenToHeaders(headers);
        return fetch(url, {
            method: 'POST',
            headers
        });
    }

    /*
        Part one of registration process - submit user name, email, password
     */
    async register(userRegistration: UserRegistration): Promise<void> {
        const url = this.baseURL + '/register';
        const headers = new Headers({'Content-Type': 'application/json'});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(userRegistration),
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
    }

    /*
        Part two of registration process - submit token which confirms email address
     */
    async registerWithToken(userRegistrationToken: UserRegistrationToken): Promise<UserFull> {
        const url = this.baseURL + '/register-token';
        const headers = new Headers({'Content-Type': 'application/json'});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(userRegistrationToken),
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async updateWithFormData(id: number, formData: FormData): Promise<UserFull> {
        const url = this.baseURL + '/formData/' + id.toString();
        const headers = new Headers({});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'PUT',
            body: formData,
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    isAdmin(userFull: UserFull): boolean {
        return userFull.roles.includes('ROLE_ADMIN');
    }

    async changePassword(changePassword: ChangePassword): Promise<void> {
        const url = this.baseURL + '/change-password';
        const headers = new Headers({'Content-Type': 'application/json'});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(changePassword),
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
    }

    async resetPassword(resetPassword: ResetPassword): Promise<void> {
        const url = this.baseURL + '/reset-password';
        const headers = new Headers({'Content-Type': 'application/json'});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(resetPassword),
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
    }

    async resetPasswordWithToken(resetPasswordToken: ResetPasswordToken): Promise<void> {
        const url = this.baseURL + '/reset-password-token';
        const headers = new Headers({'Content-Type': 'application/json'});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(resetPasswordToken),
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
    }
}