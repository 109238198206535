import {CacheStateService} from "./CacheStateService";
import {ReportService} from "../../entity/ReportService";
import {ReportCommentFullListState} from "../../../state/cache/ReportCommentFullListState";

interface ReportCommentFullListStateRefreshData {
    reportId: number;
}

export class ReportCommentFullListStateService extends CacheStateService<ReportCommentFullListState, ReportCommentFullListStateRefreshData> {
    protected isRefreshNeededStepTwo(refreshData: ReportCommentFullListStateRefreshData, reportCommentFullListState: ReportCommentFullListState) {
        return (refreshData.reportId !== reportCommentFullListState.reportId);
    }

    protected async getNewStatePromise(refreshData: ReportCommentFullListStateRefreshData, reportCommentFullListState: ReportCommentFullListState): Promise<ReportCommentFullListState> {
        const reportService = new ReportService();

        let commentFulls = await reportService.getCommentFullsByReport(refreshData.reportId);
        if (!commentFulls) {
            commentFulls = [];
        }

        return new ReportCommentFullListState(refreshData.reportId, commentFulls, false, true);
    }
}