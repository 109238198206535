import {default as React, useContext, useEffect, useState} from "react";
import {UserListState} from "../state/cache/UserListState";
import {FetchErrorService} from "../service/error/FetchErrorService";
import {TopContributorsStateService} from "../service/state/cache/TopContributorsStateService";
import {User} from "../model/user/User";
import {Link} from "react-router-dom";
import {AppConfig} from "../AppConfig";
import defaultavatar from "../images/defaultavatar.jpg";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export function TopContributorsPage() {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const [userListState, setUserListState] = useState(new UserListState());

    useEffect(() => {
        document.title = 'Top Contributors | StatusMatcher';
    });

    // Fetch top contributor users
    useEffect(() => {
        (new TopContributorsStateService()).refresh({},
            userListState,
            setUserListState)
            .catch((error: Error) => {
                (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'user');
            });
    }, [userListState, setCommunicatorState]);

    function renderTopContributorRows(users: User[]) {
        const reactNodes: React.ReactNode[] = [];
        let firstUser = true;
        for (let user of users) {
            reactNodes.push(<tr key={user.id}>
                <td>
                    <Link to={'/user/' + user.id}>
                        {user.avatarFilename ?
                            <img src={'/' + AppConfig.AvatarImagesPath + '/' + user.avatarFilename} alt="" className="avatar"/> :
                            <img src={defaultavatar} alt="" className="avatar"/>
                        }
                        &nbsp;&nbsp;{user.name}
                        {firstUser &&
                            <>&nbsp;<span className="badge rounded-pill">Top Contributor</span></>
                        }
                    </Link>
                </td>
                <td className="align-middle">
                    {user.recentReportCount}
                </td>
            </tr>);
            firstUser = false;
        }
        return reactNodes;
    }

    return (
        <div>
            <h3>Top Contributors</h3>
            <hr/>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th scope="col">User</th>
                        <th scope="col">Recent Reports*</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTopContributorRows(userListState.users)}
                </tbody>
            </table>
            <div className="mt-3">
                <i>(*) Reports submitted within the past three years are considered</i>
            </div>
        </div>
    )
}