export class CookieService {
    getCookie(cookie: string): string | undefined {
        const nameWithEquals = cookie + "=";
        const documentCookieString = decodeURIComponent(document.cookie);
        let cookieStringArray = documentCookieString.split(';');
        for(let i = 0; i <cookieStringArray.length; i++) {
            let c = cookieStringArray[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(nameWithEquals) === 0) {
                return c.substring(nameWithEquals.length, c.length);
            }
        }
        return undefined;
    }
}