import {CommunicatorState} from "../state/ui/CommunicatorState";
import {FormikErrors, FormikHelpers} from "formik";
import {FetchErrorService} from "./error/FetchErrorService";
import {HttpProblemDetailsError} from "./error/HttpProblemDetailsError";
import {ConstraintProblem} from "../model/error/ConstraintProblem";

export class FormService<VALUES_TYPE extends Object> {
    private readonly fetchErrorService = new FetchErrorService();

    idFromString(s: string): number | null {
        const retVal = parseInt(s);
        if (!isNaN(retVal) && retVal > 0) {
            return retVal;
        } else {
            return null;
        }
    }

    private formatConstraintViolation(constraintViolation: string): string {
        return constraintViolation.substr(0, 1).toUpperCase() + constraintViolation.substr(1);
    }

    // Set field level errors based on ConstraintProblem data
    private handleFieldLevelConstraintProblems(constraintProblem: ConstraintProblem,
                            formikHelpers: FormikHelpers<VALUES_TYPE>,
                            newValues: VALUES_TYPE) {
        let errors: {[index: string]: string} = {};
        const constraintViolations = constraintProblem.constraintViolations;
        for(let prop in constraintViolations) {
            // Check if constraint violation property matches a property within form values
            if(constraintViolations.hasOwnProperty(prop) && newValues.hasOwnProperty(prop)) {
                errors[prop] = this.formatConstraintViolation(constraintViolations[prop]);
            }
        }
        formikHelpers.setErrors(errors as FormikErrors<VALUES_TYPE>);
    }

    // If applicable, return a string describing top level constraint problems (e.g. problem with the set of fields, not an individual field)
    private getTopLevelContraintProblem(constraintProblem: ConstraintProblem): string {
        const constraintViolations = constraintProblem.constraintViolations;
        for(let prop in constraintViolations) {
            if (!prop) {
                // No property referenced - indicates top level problem
                return this.formatConstraintViolation(constraintViolations[prop]);
            }
        }
        return '';
    }

    handleSubmitError(error: Error,
                      setCommunicatorState: (communicatorState: CommunicatorState) => void,
                      formikHelpers: FormikHelpers<VALUES_TYPE> | undefined,
                      newValues: VALUES_TYPE,
                      includeMessagePrefix = true,
                      keepCommunicatorVisibleForOnePageChange = false) {
        // Set top level error message
        let communicatorMessage = includeMessagePrefix ? 'Form submission error: ' : '';

        // If ProblemDetails is available, try to use it
        if (error instanceof HttpProblemDetailsError) {
            const httpProblemDetails = error.httpProblemDetails;

            // If constraint details are available, try to use them
            if (this.fetchErrorService.isConstraintProblem(httpProblemDetails)) {
                const topLevelConstraintProblem = this.getTopLevelContraintProblem(httpProblemDetails);
                if (topLevelConstraintProblem) {
                    communicatorMessage = topLevelConstraintProblem;
                } else {
                    communicatorMessage = 'There was an error with the input data provided. See below for details.';
                }

                if (formikHelpers) {
                    this.handleFieldLevelConstraintProblems(httpProblemDetails, formikHelpers, newValues);
                }
            } else {
                if (httpProblemDetails.detail) {
                    communicatorMessage += httpProblemDetails.detail;
                } else {
                    communicatorMessage += error.message;
                }
            }
        } else {
            communicatorMessage += error.message;
        }

        setCommunicatorState(new CommunicatorState(communicatorMessage, 'danger', true, keepCommunicatorVisibleForOnePageChange));
        if (formikHelpers) {
            formikHelpers.setSubmitting(false);
        }
    }
}