import {useEffect} from "react";

export function GuidePage() {
    useEffect(() => {
        document.title = 'Guide to Travel Loyalty Programs | StatusMatcher';
    });


    return (
        <div>
            <h3>Guide to Travel Loyalty Programs</h3>
            <hr/>
            <h4>What are Travel Loyalty Programs?</h4>
            <p>
                They are programs designed to reward and retain a travel firm's most frequent customers. Like loyalty
                programs for grocery stores and other retailers, they are designed to build a relationship with the
                customer, and provide incentives to choose them over the competition.
            </p>

            <h4>How do they work?</h4>
            <p>
                Travel Loyalty Programs track the amount of business a particular customer provides. This is done with
                metrics such as miles flown with an airline or nights spent with a hotel chain. If enough of the
                particular metric has been accumulated, the customer will be eligible for a reward. This may be a free
                flight, a hotel stay, or a non-travel item such as a magazine subscription.
            </p>

            <h4>How does "Status" fit in?</h4>
            <p>
                When a certain level of miles or nights have been obtained in a set time period (typically a year), a
                customer is designated as having obtained a particular status level. These status levels are named
                things like gold, platinum, and silver. They typically last for the remainder of the current year and
                the entire next year.
            </p>
            <p>
                A status level provides the customer with key amenities. For example an airline may provide better
                seating, or a hotel may provide a free breakfast. As customers move to higher status levels, the
                amenities become quite valuable and represent a strong incentive to stay with a particular travel firm.
            </p>

            <h4>What is "Status Matching"?</h4>
            <p>
                A method travel firms use to get frequent customers of another firm to switch to them. If a customer has
                achieved a particular status level, with all of its associated amenities, there is a strong incentive
                for him or her to stay with the current firm. To combat this, a competing firm will offer the customer
                an equivalent status level within their own program. This makes it easier for customers to make the
                switch.
            </p>
            <p>
                <span className="guide-highlight">StatusMatcher.com</span> provides the details of these matching
                opportunities.
            </p>

            <h4>What should I look for in a loyalty program?</h4>
            <p>
                The first thing to look for are programs that offer the best amenities. For example, some airlines offer
                complementary upgrades to business or first class, if there is seat availability. This is a valuable
                benefit which is not provided by all airlines. In the hotel industry, look for things like room
                upgrades, free breakfast, and free internet service.
            </p>
            <p>
                The second thing to consider is what it takes to obtain these amenities. Some programs provide great
                amenities, but require a high number of miles or nights in order to get them. For example, an airlines
                may provide complementary seat upgrades, but requires 100,000 miles to achieve the status which includes
                this amenity. 100,000 miles is a lot of flying with a particular airline, even for business travelers.
                Look for the programs that offer good amenities, but relatively low requirements to obtain them.
            </p>
        </div>
    );
}