import {FieldConfig, useField} from "formik";

export interface FormCheckboxProps {
    label: string;
    formText?: string;
}

export function FormCheckbox({label, formText, ...props}: FormCheckboxProps & JSX.IntrinsicElements['input'] & FieldConfig<boolean>) {
    const [fieldInputProps, fieldMetaProps] = useField<boolean>(props);
    return (
        <div className="form-check">
            <input type="checkbox"
                   className={'form-check-input' + ((fieldMetaProps.touched && fieldMetaProps.error) ? ' is-invalid' : '')}
                   name={fieldInputProps.name}
                   multiple={fieldInputProps.multiple}
                   onChange={fieldInputProps.onChange}
                   onBlur={fieldInputProps.onBlur}
                   checked={fieldInputProps.value}
            />
            <label htmlFor={props.id || props.name} className="form-check-label">{label}</label>
            {fieldMetaProps.touched && fieldMetaProps.error
                ? <div className="invalid-feedback">{fieldMetaProps.error}</div>
                : null}
            {formText
                ? <div className="form-text">{formText}</div>
                : null}
        </div>
    );
}
