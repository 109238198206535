import {Result} from "./Result";

export class Report {
    id: number | null = null;
    instructions: string = '';
    result: Result = Result.MATCH;
    published: boolean = false;
    read: boolean = false;
    fromProgramId: number | null = null;
    fromStatusId: number | null = null;
    toProgramId: number | null = null;
    toStatusId: number | null = null;
    userId: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
}