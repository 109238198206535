import {CommentFull} from "../model/comment/CommentFull";
import {Link} from "react-router-dom";
import {AppConfig} from "../AppConfig";
import defaultavatar from "../images/defaultavatar.jpg";
import {default as React, SyntheticEvent, useContext, useState} from "react";
import {DateService} from "../service/DateService";
import {CommentFormInline} from "./CommentFormInline";
import {CommunicatorState} from "../state/ui/CommunicatorState";
import {Comment} from "../model/comment/Comment";
import {ConfirmModal} from "../component/modal/ConfirmModal";
import {CommentService} from "../service/entity/CommentService";
import {FormService} from "../service/FormService";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface StatusmatcherCommentProps {
    commentFull: CommentFull;
    setCommunicatorState: (communicatorState: CommunicatorState) => void;
    onSubmit: (comment: Comment) => void;
    onDelete: (commentId: number) => void;
}

export function StatusmatcherComment(props: StatusmatcherCommentProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const [editMode, setEditMode] = useState(false);
    const [replyMode, setReplyMode] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

    const commentFull = props.commentFull;
    const user = props.commentFull.user;
    const dateService = new DateService();
    const createdAt = dateService.ISOStringToDateTimeDisplayString(commentFull.createdAt);
    const canEdit = commentFull._metadata.commands.includes('edit');
    const canDelete = commentFull._metadata.commands.includes('delete');
    const canReply = commentFull._metadata.commands.includes('reply');

    function getIndentFromDepth(depth: number) {
        switch (depth) {
            case 0:
            case 1:
                return '0px';
            case 2:
                return '58px';
            case 3:
                return '96px';
            case 4:
            default:
                return '130px';
        }
    }

    function getAvatarClassFromDepth(depth: number) {
        switch (depth) {
            case 0:
            case 1:
                return 'avatar';
            default:
                return 'avatar-small';
        }
    }

    function onEdit(e: SyntheticEvent) {
        e.preventDefault();
        setEditMode(true);
    }

    function onDelete(e: SyntheticEvent) {
        e.preventDefault();
        setShowDeleteConfirmModal(true);
    }

    function onDeleteConfirm() {
        const commentService = new CommentService();
        const formService = new FormService<{}>();

        if (!props.commentFull.id) return;
        const commentId: number = props.commentFull.id;

        setShowDeleteConfirmModal(false);

        commentService.deleteEntity(commentId)
            .then(() => {
                props.onDelete(commentId);
            })
            .catch((error: Error) => {
                formService.handleSubmitError(error, setCommunicatorState, undefined, {}, false);
            })
    }

    function onDeleteNotConfirmed() {
        setShowDeleteConfirmModal(false);
    }

    function onReply(e: SyntheticEvent) {
        e.preventDefault();
        setReplyMode(true);
    }

    function onCommentSubmit(comment: Comment) {
        setEditMode(false);
        setReplyMode(false);
        props.onSubmit(comment);
    }

    function onEditCancel() {
        setEditMode(false);
    }

    function onReplyCancel() {
        setReplyMode(false);
    }

    function renderCommands() {
        return <p className="text-end">
            {canEdit && <a href="#edit" className="inline-command" onClick={onEdit}>Edit</a>}
            {canDelete && <a href="#delete" className="inline-command" onClick={onDelete}>Delete</a>}
            {canReply && <a href="#reply" className="inline-command" onClick={onReply}>Reply</a>}
        </p>
    }

    if (user === null)
        return null;

    return <div className="d-flex my-1">
        <div style={{
            marginLeft: getIndentFromDepth(commentFull.depth),
            marginTop: '7px'
        }}>
            {commentFull.deleted ?
                <img src={defaultavatar} alt="" className={getAvatarClassFromDepth(commentFull.depth)}/> :
                <Link to={'/user/' + user.id}>
                    {user.avatarFilename ?
                        <img src={'/' + AppConfig.AvatarImagesPath + '/' + user.avatarFilename} alt=""
                             className={getAvatarClassFromDepth(commentFull.depth)}/> :
                        <img src={defaultavatar} alt="" className={getAvatarClassFromDepth(commentFull.depth)}/>
                    }
                </Link>
            }
        </div>
        <div className="flex-grow-1 ms-3">
            {commentFull.deleted ?
                <div className="mb-5">
                    <i>This comment has been deleted</i>
                </div> :
                <>
                    <small><i>
                        From: <Link to={'/user/' + user.id}>{user.name}</Link> on {createdAt}
                    </i></small>
                    {editMode ?
                        <CommentFormInline
                            edit={true}
                            commentFull={commentFull}
                            user={user}
                            onSubmit={onCommentSubmit}
                            onCancel={onEditCancel}
                        /> :
                        <p className="comment-body text-break">{commentFull.body}</p>
                    }
                    {replyMode &&
                    <CommentFormInline
                        edit={false}
                        commentFull={commentFull}
                        user={user}
                        onSubmit={onCommentSubmit}
                        onCancel={onReplyCancel}
                    />
                    }
                    {(!editMode && !replyMode && (canDelete || canEdit || canReply)) &&
                    renderCommands()
                    }
                </>
            }
        </div>
        <ConfirmModal
            show={showDeleteConfirmModal}
            text="Delete comment?"
            onConfirm={onDeleteConfirm}
            onClose={onDeleteNotConfirmed}
        />
    </div>
}