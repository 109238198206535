import {CacheStateService} from "./CacheStateService";
import {UserListState} from "../../../state/cache/UserListState";
import {UserService} from "../../entity/UserService";
import {User} from "../../../model/user/User";

export class TopContributorsStateService extends CacheStateService<UserListState, {}> {
    protected async getNewStatePromise(refreshData: object, userListState: UserListState): Promise<UserListState> {
        const userService = new UserService();

        // Get a list of user objects which are the top contributors
        const users: User[] = await userService.getAllViews<User>('topContributors');

        return new UserListState(users, false, true);
    }
}