import {Form, Formik, FormikHelpers} from "formik";
import {Button, Spinner} from "react-bootstrap";
import {FormService} from "../service/FormService";
import {UserService} from "../service/entity/UserService";
import {FormInput} from "../component/form/FormInput";
import {FormImageFileInput} from "../component/form/FormImageFileInput";
import {Link} from "react-router-dom";
import {AppConfig} from "../AppConfig";
import {UserFull} from "../model/user/UserFull";
import {UserFullState} from "../state/cache/UserFullState";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface UserFormProps {
    currentUserState: UserFullState;
    userFull: UserFull;
    onSubmit: (userFull: UserFull) => void;
}

interface UserFormValues {
    name: string;
    email: string;
    avatarFilename: string;     // Existing avatar file name
    avatarFile: File | null;    // New avatar file to be uploaded
}

interface UserFormValidation {
    name?: string;
    avatarFile?: string;
}

export function UserForm(props: UserFormProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const userFull = props.userFull ? props.userFull : new UserFull();
    const userIsCurrentUser = (props.currentUserState.userFull && props.currentUserState.userFull.id === userFull.id);

    const initialValues: UserFormValues = {
        name: userFull.name,
        email: userFull.email,
        avatarFilename: userFull.avatarFilename,
        avatarFile: null
    };

    function handleSubmit(userFormValues: UserFormValues, formikHelpers: FormikHelpers<UserFormValues>) {
        if (!props.userFull || !props.userFull.id) {
            return;
        }

        // Submit FormData, as it supports binary file data
        const formData = new FormData();

        formData.append('name', userFormValues.name);
        formData.append('email', userFormValues.email);
        formData.append('avatarFilename', userFormValues.avatarFilename);
        if (userFormValues.avatarFile) {
            formData.append('avatarFile', userFormValues.avatarFile);
        }

        const userService = new UserService();
        const formService = new FormService<UserFormValues>();

        userService.updateWithFormData(props.userFull.id, formData)
            .then((userFullReturned: UserFull) => {
                formikHelpers.setSubmitting(false);
                if (props.onSubmit) {
                    props.onSubmit(userFullReturned);
                }
            })
            .catch((error: Error) => {
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    userFormValues);
            });
    }

    function validate(userFormValues: UserFormValues) {
        const errors: UserFormValidation = {};

        if (!userFormValues.name) {
            errors.name = 'Required';
        }

        if (userFormValues.avatarFile !== null) {
            const filename = userFormValues.avatarFile.name;
            if (!AppConfig.AvatarImageAcceptedExtensions.some(extension => {
                return filename.endsWith('.' + extension);
            })) {
                errors.avatarFile = 'File type is not supported';
            } else if (userFormValues.avatarFile.size > AppConfig.AvatarImageMaxSize) {
                errors.avatarFile = 'File is too large';
            }
        }

        return errors;
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form noValidate={true}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Name *" name="name"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Email Address *" name="email" readOnly={true}/>
                        </div>
                    </div>
                    {userIsCurrentUser ?
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div>
                                    <label htmlFor="changePassword" className="form-label">Password</label>
                                    <div>
                                        <Link to="/change-password">Change password</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    <div className="row mb-3">
                        <div className="col-lg-9">
                            <FormImageFileInput
                                label="Image"
                                name="avatarFile"
                                existingImageField="avatarFilename"
                                existingImagePath={'/' + AppConfig.AvatarImagesPath + '/'}
                                formText="Maximum file size: 100KB. Accepted image types: JPG, PNG, GIF."
                                acceptExtensions={AppConfig.AvatarImageAcceptedExtensions.map(e => ('.' + e)).join()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Link to={userFull.id ? '/user/' + userFull.id : '/'} className="btn btn-secondary me-2">Cancel</Link>
                            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                Save
                                {
                                    formik.isSubmitting &&
                                    <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                                }
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}