import {UserState} from "../../../state/cache/UserState";
import {CacheStateService} from "./CacheStateService";
import {UserService} from "../../entity/UserService";

interface UserStateRefreshData {
    id: number;
}

export class UserStateService extends CacheStateService<UserState, UserStateRefreshData> {
    protected isRefreshNeededStepTwo(refreshData: UserStateRefreshData, userState: UserState) {
        // If the requested user does not match the user in the current state, need a refresh
        return (refreshData.id > 0 &&
            (!userState.user || !userState.user.id || userState.user.id !== refreshData.id));
    }

    protected async getNewStatePromise(refreshData: UserStateRefreshData, userState: UserState): Promise<UserState> {
        if (refreshData.id) {
            const user = await (new UserService()).getOne(refreshData.id);

            return new UserState(user, false, true);
        } else {
            return new UserState(null, false, false);
        }
    }
}