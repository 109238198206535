import {PageMetadata} from "../../model/PageMetadata";
import {CacheState} from "./CacheState";

export class EntityListState<T> implements CacheState {
    constructor(
        public entityName: string = '',
        public entityView: string = '',
        public pageMetadata: PageMetadata = new PageMetadata(),
        public entities: T[] = [],
        public pendingRequest: boolean = false,
        public loaded: boolean = false
    ) {
    }
}