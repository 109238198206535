import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {FormInput} from "../component/form/FormInput";
import {Button, Spinner} from "react-bootstrap";
import {ResetPasswordToken} from "../model/user/ResetPasswordToken";
import {UserService} from "../service/entity/UserService";
import {FormService} from "../service/FormService";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface ResetPasswordTokenFormProps {
    token: string;
    onSubmit: () => void;
}

export function ResetPasswordTokenForm(props: ResetPasswordTokenFormProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const initialValues: ResetPasswordToken = {
        token: props.token,
        password: '',
        confirmPassword: ''
    };

    function handleSubmit(resetPasswordToken: ResetPasswordToken, formikHelpers: FormikHelpers<ResetPasswordToken>) {
        const userService = new UserService();
        userService.resetPasswordWithToken(resetPasswordToken)
            .then(() => {
                formikHelpers.setSubmitting(false);
                props.onSubmit();
            })
            .catch((error: Error) => {
                const formService = new FormService<ResetPasswordToken>();
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    resetPasswordToken,
                    false);
            });
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                password: Yup.string().required('Required')
                    .min(6, 'Must be at least 6 characters long'),
                confirmPassword: Yup.string()
                    .required('Required')
                    .min(6, 'Must be at least 6 characters long')
                    .oneOf([Yup.ref('password')], 'Passwords don\'t match')
            })}
            onSubmit={handleSubmit}>
            {formik => (
                <Form noValidate={true}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Password *" name="password" type="password"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput label="Confirm Password *" name="confirmPassword" type="password"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                Save
                                {
                                    formik.isSubmitting &&
                                    <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                                }
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}