import {ReportListState} from "./ReportListState";
import {ReportSummary} from "../../model/report/ReportSummary";
import {PageMetadata} from "../../model/PageMetadata";

export class ProgramReportListState extends ReportListState {
    constructor(public programId: number | undefined = undefined,
                public to: boolean = true,
                reportSummaries: ReportSummary[] = [],
                pageMetadata: PageMetadata = new PageMetadata(),
                pendingRequest: boolean = false,
                loaded: boolean = false) {
        super(reportSummaries, pageMetadata, pendingRequest, loaded);
    }
}