import {CacheStateService} from "./CacheStateService";
import {UserService} from "../../entity/UserService";
import {UserFullState} from "../../../state/cache/UserFullState";

export class CurrentUserStateService extends CacheStateService<UserFullState, {}> {
    protected async getNewStatePromise(refreshData: object, userFullstate: UserFullState): Promise<UserFullState> {
        const userService = new UserService();
        const userFull = await userService.getCurrent();

        return new UserFullState(userFull, false, true);
    }
}