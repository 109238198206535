import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {useHistory, useParams} from "react-router";
import {default as React, useContext, useEffect, useState} from "react";
import {MessageFull} from "../../model/message/MessageFull";
import {FetchErrorService} from "../../service/error/FetchErrorService";
import {EntityState} from "../../state/cache/EntityState";
import {EntityPageRouteParams} from "../EntityPageRouteParams";
import {EntityStateService} from "../../service/state/cache/EntityStateService";
import {Link} from "react-router-dom";
import {DateService} from "../../service/DateService";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";
import {EntityActions} from "../../component/EntityActions";

export function MessagePage() {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const entityPageRouteParams = useParams<EntityPageRouteParams>();
    const [entityFullState, setEntityFullState] = useState(new EntityState<MessageFull>());
    const history = useHistory();

    // Page title
    useEffect(() => {
        if (entityFullState.entity && entityFullState.entity.subject) {
            document.title = entityFullState.entity.subject + ' | StatusMatcher';
        } else {
            document.title = 'Message | StatusMatcher';
        }
    });

    // Entity state
    useEffect(() => {
        if (entityPageRouteParams.id) {
            const entityId: number = parseInt(entityPageRouteParams.id);
            const entityStateService = new EntityStateService<MessageFull>('message', 'full');
            entityStateService.refresh({id: entityId}, entityFullState, setEntityFullState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'message');
                });
        }
    }, [entityPageRouteParams.id, entityFullState, setCommunicatorState]);

    // Handle case where entity is not loaded
    if (!entityFullState.entity) return null;

    function onDelete() {
        history.push('/');
        setCommunicatorState(new CommunicatorState('Message deleted',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
    }

    function renderMessage(messageFull: MessageFull) {
        const dateService = new DateService();

        const userName = messageFull.user ? messageFull.user.name : messageFull.name;
        const email = messageFull.user ? messageFull.user.email : messageFull.email;
        const createdAt = dateService.ISOStringToDateDisplayString(messageFull.createdAt);

        return <div>
            <h3>{messageFull.subject}</h3>
            <hr/>
            <p>{messageFull.message}</p>
            <div>
                <small>
                    <strong>From:</strong>&nbsp;
                    {messageFull.user ?
                        <Link to={'/user/' + messageFull.user.id}>{userName}</Link> :
                        userName
                    }
                    &nbsp;({email}) on {createdAt}
                </small>
            </div>
            <EntityActions<MessageFull>
                small={false}
                entitySchema={MessageFull.AdminSchema}
                entity={messageFull}
                onDelete={onDelete}
                displayedActions={['delete']}
            />
        </div>
    }

    return renderMessage(entityFullState.entity);
}