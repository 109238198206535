import {EntitySchema} from "./schema/EntitySchema";
import {FieldDefinition} from "./schema/FieldDefinition";
import {BaseEntity} from "../model/BaseEntity";
import {FieldType} from "./schema/FieldType";
import {Link} from "react-router-dom";
import {DateService} from "../service/DateService";
import {EntityActions} from "./EntityActions";
import {PageMetadata} from "../model/PageMetadata";
import {PaginationService} from "../service/PaginationService";
import {Button} from "react-bootstrap";
import {Report} from "../model/report/Report";
import {User} from "../model/user/User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {default as React} from "react";
import {AppConfig} from "../AppConfig";

export interface EntityTableProps<T extends BaseEntity> {
    entitySchema: EntitySchema;
    entities: T[];
    highlightUnread: boolean;
    onPublish?: (entity: T) => void;
    onDelete?: (entity: T) => void;
    pageMetadata: PageMetadata;
    onPreviousPage: () => void;
    onNextPage: () => void;
    displayedActions: string[];
}

export function EntityTable<T extends BaseEntity>(props: EntityTableProps<T>) {
    const isPreviousPageEnabled = PaginationService.isPreviousPageEnabled(props.pageMetadata);
    const isNextPageEnabled = PaginationService.isNextPageEnabled(props.pageMetadata);

    function renderTableHeadCell(fieldDefinition: FieldDefinition) {
        const styleObject = fieldDefinition.displayWidth ?
            {width: fieldDefinition.displayWidth.toString() + '%'} :
            {};

        return <th key={fieldDefinition.name} style={styleObject} scope="col">
            {fieldDefinition.displayName}
        </th>
    }

    function renderTableHead() {
        return <thead>
        <tr>
            {props.entitySchema.fieldDefinitions.map(renderTableHeadCell)}
            {props.displayedActions.length > 0 &&
            <th key="actions" scope="col">Actions</th>
            }
        </tr>
        </thead>
    }

    function renderField(fieldDefinition: FieldDefinition, val: any) {
        switch (fieldDefinition.fieldType) {
            case FieldType.String:
                return <>{val}</>;
            case FieldType.Number:
                return <>{val}</>;
            case FieldType.Boolean:
                return <>{val ?
                    <span className="text-success">
                        <FontAwesomeIcon icon={['far', 'check-circle']}/>
                    </span> :
                    null}
                </>;
            case FieldType.Link:
                return <Link to={'/' + props.entitySchema.name + '/' + val}>{val}</Link>;
            case FieldType.Date:
                return <>{(new DateService()).ISOStringToShortDateDisplayString(val)}</>;
            case FieldType.Report:
                const report: Report = (val as Report);
                return <Link to={'/report/' + report.id}>Report {report.id}</Link>;
            case FieldType.User:
                if (val) {
                    const user: User = (val as User);
                    return <Link to={'/user/' + user.id}>{user.name}</Link>;
                } else {
                    return null;
                }
            case FieldType.Avatar:
                return val ?
                    <img src={'/' + AppConfig.AvatarImagesPath + '/' + val} alt="" className="avatar-small"/> :
                    null;
            default:
                return null;
        }
    }

    function renderActionsCell(entity: T) {
        return <td key="actions">
            <EntityActions<T>
                small={true}
                entitySchema={props.entitySchema}
                entity={entity}
                onPublish={props.onPublish}
                onDelete={props.onDelete}
                displayedActions={props.displayedActions}
            />
        </td>
    }

    function renderTableCell(fieldDefinition: FieldDefinition, entity: T) {
        return <td key={fieldDefinition.name}>
            {renderField(fieldDefinition, entity[fieldDefinition.name as keyof T])}
        </td>
    }

    function renderTableRow(entity: T) {
        let className = '';
        if (props.highlightUnread && !entity['read' as keyof T]) {
            className += 'table-warning';
        }

        return <tr key={entity.id} className={className}>
            {props.entitySchema.fieldDefinitions.map(fieldDefinition => renderTableCell(fieldDefinition, entity))}
            {props.displayedActions.length > 0 && renderActionsCell(entity)}
        </tr>
    }

    function renderTableBody() {
        return <tbody>
        {props.entities.map(entity => renderTableRow(entity))}
        </tbody>
    }

    return <>
        <table className="table table-sm entity-table">
            {renderTableHead()}
            {renderTableBody()}
        </table>
        {isPreviousPageEnabled || isNextPageEnabled ?
            <div className="text-center">
                <Button variant="light"
                        size="sm"
                        disabled={!isPreviousPageEnabled}
                        className={'me-3'}
                        onClick={props.onPreviousPage}>
                    &lt; Previous Page
                </Button>
                <Button variant="light"
                        size="sm"
                        disabled={!isNextPageEnabled}
                        className={'ms-3'}
                        onClick={props.onNextPage}>
                    Next Page &gt;
                </Button>
            </div>
            : null}
    </>
}

EntityTable.defaultProps = {
    actions: []
};