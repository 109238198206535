import {PagedModel} from "../../model/PagedModel";
import {FetchErrorService} from "../error/FetchErrorService";
import {CookieService} from "../CookieService";

export class FetchEntityService<ENTITY_TYPE> {
    static readonly CSRFTokenCookie = 'XSRF-TOKEN';
    static readonly CSRFTokenHeader = 'X-XSRF-TOKEN';
    protected readonly fetchErrorService = new FetchErrorService();

    readonly modelName: string;
    readonly baseURL: string;

    constructor(modelName: string) {
        this.modelName = modelName;
        this.baseURL = '/api/' + modelName;
    }

    async getOne(id: number): Promise<ENTITY_TYPE> {
        const url = this.baseURL + '/' + id.toString();
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async getOneView<VIEW_TYPE>(id: number, view: string): Promise<VIEW_TYPE> {
        const url = this.baseURL + '/' + id.toString() + '?view=' + view;
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async getAll(): Promise<ENTITY_TYPE[]> {
        const url = this.baseURL;
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async getAllViews<VIEW_TYPE>(view: string): Promise<VIEW_TYPE[]> {
        const url = this.baseURL + '?view=' + view;
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async getPage(page: number, size: number): Promise<PagedModel<ENTITY_TYPE>> {
        const url = this.baseURL + '?page=' + page.toString() + '&size=' + size.toString();
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async getPageViews<VIEW_TYPE>(page: number, size: number, view: string): Promise<PagedModel<VIEW_TYPE>> {
        const url = this.baseURL + '?page=' + page.toString()
            + '&size=' + size.toString()
            + '&view=' + view;
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async getSummaryDataNumber(summaryDataName: string): Promise<number> {
        const url = this.baseURL + '/' + summaryDataName;
        const response = await fetch(url);
        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    csrfTokenFromCookies(): string | undefined {
        const cookieService = new CookieService();
        return cookieService.getCookie(FetchEntityService.CSRFTokenCookie);
    }

    appendCsrfTokenToHeaders(headers: Headers) {
        const csrfToken = this.csrfTokenFromCookies();
        if (csrfToken === undefined) {
            throw new Error('Missing CSRF token');
        } else {
            headers.append(FetchEntityService.CSRFTokenHeader, csrfToken);
        }
    }

    async newEntity(model: ENTITY_TYPE): Promise<ENTITY_TYPE> {
        const url = this.baseURL;
        const headers = new Headers({'Content-Type': 'application/json'});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async update(id: number, model: ENTITY_TYPE): Promise<ENTITY_TYPE> {
        const url = this.baseURL + '/' + id.toString();
        const headers = new Headers({'Content-Type': 'application/json'});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(model),
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }

    async deleteEntity(id: number): Promise<void> {
        const url = this.baseURL + '/' + id.toString();
        const headers = new Headers();
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'DELETE',
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
    }

    async action(id: number, action: string): Promise<ENTITY_TYPE> {
        const url = this.baseURL + '/' + id.toString() + '/' + action;
        const headers = new Headers({});
        this.appendCsrfTokenToHeaders(headers);

        const response = await fetch(url, {
            method: 'POST',
            headers
        });

        await this.fetchErrorService.checkResponseForError(response);
        return await response.json();
    }
}