import {Link} from "react-router-dom";

export function Footer() {
    return (
        <div>
            <br/>
            <br/>
            <hr/>
            <br/>
            <div className="text-center text-muted">
                Copyright Statusmatcher LLC. All Rights Reserved. | <Link to="/about">About</Link> | <Link to="/contact">Contact</Link> | <Link to="/sitemap">Site Map</Link> | <Link to="/terms">Terms and Conditions</Link>
            </div>
            <br/>
        </div>
    );
}