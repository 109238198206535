import * as React from "react";
import {Link} from "react-router-dom";
import {ReportService} from "../service/entity/ReportService";
import {DateService} from "../service/DateService";
import {ReportSummary} from "../model/report/ReportSummary";
import {RenderListItemProps} from "../component/ListView";

export function ReportListItem(props: RenderListItemProps<ReportSummary>): React.ReactNode {
    const reportService = new ReportService();
    const dateService = new DateService();
    const reportSummary: ReportSummary = props.listItem;

    const result = reportService.formatResult(reportSummary.result);
    const dateDisplayString = dateService.ISOStringToDateDisplayString(reportSummary.createdAt);
    const voteDescription = reportService.getVoteDescription(reportSummary);
    const commentDescription = reportService.getCommentDescription(reportSummary);

    return (
        <div className="d-flex mb-3" key={reportSummary.id}>
            <div className="pe-3">
                <Link to={'/report/' + reportSummary.id}>
                    <div className={'result-box result-box-' + reportSummary.result.toLowerCase()}>
                        {result}
                    </div>
                </Link>
            </div>
            <div className="pe-3 report-list-item-middle-box instructions text-break">
                <Link to={'/report/' + reportSummary.id}>
                    <h6 className="mb-1">
                        {reportSummary.fromProgram ? (reportSummary.fromProgram + ' to ') : ''}
                        {reportSummary.toProgram}
                    </h6>
                </Link>
                {reportSummary.instructions}
            </div>
            <div className="d-none d-md-block text-end report-list-item-right-box small">
                <span className="fw-bold">From:</span>&nbsp;
                {reportSummary.userId !== null
                    ? <Link to={'/user/' + reportSummary.userId}>{reportSummary.user}</Link>
                    : reportSummary.user
                }
                <br/>
                {dateDisplayString}
                {voteDescription && <>
                    <br/>
                    {voteDescription}
                </>}
                {commentDescription && <>
                    <br/>
                    {commentDescription}
                </>}
            </div>
        </div>
    );
}