export class ScrollService {
    static readonly FixedNavBarHeight = 70;

    static scrollToElement(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            const y = element.getBoundingClientRect().top + window.scrollY - ScrollService.FixedNavBarHeight;
            window.scroll({
                top: y,
                behavior: 'auto'
            });
        }
    }

    static scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
}