import {CacheStateService} from "./CacheStateService";
import {NumberState} from "../../../state/cache/NumberState";
import {FetchEntityService} from "../../entity/FetchEntityService";

interface SummaryDataNumberStateRefreshData {
    entityName: string;
    summaryDataName: string;
}

export class SummaryDataNumberStateService extends CacheStateService<NumberState, SummaryDataNumberStateRefreshData> {
    protected async getNewStatePromise(refreshData: SummaryDataNumberStateRefreshData, numberState: NumberState): Promise<NumberState> {
        const fetchEntityService = new FetchEntityService(refreshData.entityName);

        const summaryDataNumber = await fetchEntityService.getSummaryDataNumber(refreshData.summaryDataName);
        return new NumberState(summaryDataNumber, false, true);
    }
}