import * as React from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {UserFullState} from "../state/cache/UserFullState";

export interface NavigationBarProps {
    currentUserState: UserFullState;
}

export function NavigationBar(props: NavigationBarProps) {
    return (
        <Navbar bg="light" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand><strong><Link className="navbar-brand" to="/">StatusMatcher</Link></strong></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link className="nav-link" to="/airlines">Airlines</Link>
                        <Link className="nav-link" to="/hotels">Hotels</Link>
                        <Link className="nav-link" to="/auto">Auto</Link>
                        <Link className="nav-link" to="/guide">Guide</Link>
                        <Link className="nav-link" to="/report/create">Create Report</Link>
                        {props.currentUserState.userFull ?
                            <Link className="nav-link d-lg-none" to="/logout">Logout</Link>
                            :
                            <><Link className="nav-link d-lg-none" to="/login">Login</Link>
                                <Link className="nav-link d-lg-none" to="/register">Register</Link></>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}