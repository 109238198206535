import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {FormInput} from "../component/form/FormInput";
import {Button, Spinner} from "react-bootstrap";
import {UserService} from "../service/entity/UserService";
import {FormService} from "../service/FormService";
import {ResetPassword} from "../model/user/ResetPassword";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface ResetPasswordFormProps {
    onSubmit: (email: string) => void;
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const initialValues: ResetPassword = {
        email: ''
    };

    function handleSubmit(resetPassword: ResetPassword, formikHelpers: FormikHelpers<ResetPassword>) {
        const userService = new UserService();
        userService.resetPassword(resetPassword)
            .then(() => {
                formikHelpers.setSubmitting(false);
                props.onSubmit(resetPassword.email);
            })
            .catch((error: Error) => {
                const formService = new FormService<ResetPassword>();
                formService.handleSubmitError(error,
                    setCommunicatorState,
                    formikHelpers,
                    resetPassword,
                    false);
            });
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                email: Yup.string().required('Required')
            })}
            onSubmit={handleSubmit}>
            {formik => (
                <Form noValidate={true}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <FormInput
                                label="Email Address *"
                                name="email"
                                formText="Requires your existing email address used with StatusMatcher"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button type="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                Reset Password
                                {
                                    formik.isSubmitting &&
                                    <span>&nbsp;<Spinner as="span" animation="border" size="sm"/></span>
                                }
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}