import {FieldConfig, useField} from "formik";

export interface FormTextareaProps {
    label: string;
    formText?: string;
    rows?: number;
}

export function FormTextarea({label, formText, ...props}: FormTextareaProps & JSX.IntrinsicElements['textarea'] & FieldConfig<string>) {
    const [fieldInputProps, fieldMetaProps] = useField<string>(props);
    return (
        <div>
            {label &&
            <label htmlFor={props.id || props.name} className="form-label">{label}</label>
            }
            <textarea
                className={'form-control' + ((fieldMetaProps.touched && fieldMetaProps.error) ? ' is-invalid' : '')}
                rows={props.rows}
                {...fieldInputProps}/>
            {fieldMetaProps.touched && fieldMetaProps.error
                ? <div className="invalid-feedback">{fieldMetaProps.error}</div>
                : null}
            {formText
                ? <div className="form-text">{formText}</div>
                : null}
        </div>
    );
}

FormTextarea.defaultProps = {
    rows: 7
};