import {PageMetadata} from "../model/PageMetadata";
import {Button} from "react-bootstrap";

export interface RenderListItemProps<T> {
    listItem: T
}

export interface ListViewProps<T> {
    listItems: T[];
    pageMetadata: PageMetadata;
    renderListItem: (props: RenderListItemProps<T>) => React.ReactNode;
    pluralListItemName: string;
    showSummary: boolean;
    onPreviousPage: () => void;
    onNextPage: () => void;
}

export function ListView<T>(props: ListViewProps<T>) {
    // Handle case of no data
    if (!props.listItems || props.listItems.length === 0)
        return null;

    const firstListItemIndex = (props.pageMetadata.number * props.pageMetadata.size) + 1;
    const lastListItemIndex = Math.min(firstListItemIndex + props.pageMetadata.size - 1, props.pageMetadata.totalElements);
    const paginationSummary = 'Viewing ' + firstListItemIndex + '-' + lastListItemIndex + ' of ' +
        props.pageMetadata.totalElements + ' ' + props.pluralListItemName;
    const isPreviousPageButtonDisabled = props.pageMetadata.number === 0;
    const isNextPageButtonDisabled = lastListItemIndex >= props.pageMetadata.totalElements;

    const renderedListItems = props.listItems.map(
        (listItem: T) => props.renderListItem({listItem}));

    return (
        <div>
            <div>
                {renderedListItems}
            </div>
            {props.showSummary || !isPreviousPageButtonDisabled || !isNextPageButtonDisabled ?
                <div className="text-center">
                    <Button variant="light"
                            size="sm"
                            disabled={isPreviousPageButtonDisabled}
                            className={'me-3'}
                            onClick={props.onPreviousPage}>
                        &lt; Previous Page
                    </Button>
                    {props.showSummary ? <span className="text-muted">{paginationSummary}</span> : null}
                    <Button variant="light"
                            size="sm"
                            disabled={isNextPageButtonDisabled}
                            className={'ms-3'}
                            onClick={props.onNextPage}>
                        Next Page &gt;
                    </Button>
                </div>
                : null}
        </div>
    );
}