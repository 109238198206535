import {Button, Modal} from "react-bootstrap";

export interface ConfirmModalProps {
    show: boolean;
    text: string;
    onConfirm: () => void;
    onClose: () => void;
}

export function ConfirmModal(props: ConfirmModalProps) {
    return <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.text}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={props.onConfirm}>
                Ok
            </Button>
            <Button variant="secondary" onClick={props.onClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}