import {useField} from "formik";
import {useState} from "react";

export interface FormImageFileInputProps {
    label: string;
    name: string;
    existingImageField: string;
    existingImagePath: string;
    formText?: string;
    acceptExtensions?: string;
}

/*
    Input of type "file"
    Allows user to select an image file
    Also displays an existing image file if present

    Note: the HTML file input is read only from JavaScript - must be treated as an uncontrolled component
 */
export function FormImageFileInput(props: FormImageFileInputProps) {
    // Existing image
    // eslint-disable-next-line
    const [existingImageFieldInputProps, existingImageFieldMetaProps, existingImageFieldHelperProps] = useField<string>(props.existingImageField);

    // New image
    // eslint-disable-next-line
    const [fieldInputProps, fieldMetaProps, fieldHelperProps] = useField<File | null>(props.name);
    const [newImageURL, setNewImageURL] = useState('');

    function handleRemove() {
        // Clear existing and new image
        existingImageFieldHelperProps.setValue('');
        fieldHelperProps.setValue(null);
        setNewImageURL('');
    }

    // Display either: new image that user has selected, existing image, or no image
    const imageURL = newImageURL ? newImageURL : (existingImageFieldInputProps.value ? (props.existingImagePath + existingImageFieldInputProps.value) : undefined);

    return (
        <div>
            <label htmlFor={props.name} className="form-label">{props.label}</label>
            <p>
                {imageURL ?
                    <>
                        <img src={imageURL} alt="" className="avatar me-3"/>
                        <button type="button" className="btn btn-outline-danger btn-sm" onClick={handleRemove}>
                            Remove
                        </button>
                    </>
                    : <>(None)</>
                }
            </p>
            <input type="file"
                   name={props.name}
                   accept={props.acceptExtensions}
                   className={'form-control' + ((fieldMetaProps.error) ? ' is-invalid' : '')}
                   onChange={(event) => {
                       if (event && event.target && event.target.files && event.target.files[0]) {
                           // Clear existing image
                           existingImageFieldHelperProps.setValue('');

                           // Need to explicitly set value of type File
                           fieldHelperProps.setValue(event.target.files[0]);

                           // Create a temporary (client side) URL for file, for preview image
                           setNewImageURL(URL.createObjectURL(event.target.files[0]));
                       } else {
                           fieldHelperProps.setValue(null);
                           setNewImageURL('');
                       }
                   }}
            />
            {fieldMetaProps.error
                ? <div className="invalid-feedback">{fieldMetaProps.error}</div>
                : null}
            {props.formText
                ? <div className="form-text">{props.formText}</div>
                : null}
        </div>
    );
}

FormImageFileInput.defaultProps = {
    acceptExtensions: ".jpg,.jpeg,.png,.gif"
};