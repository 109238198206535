import {Link, useHistory} from "react-router-dom";
import {UserService} from "../service/entity/UserService";
import {CommunicatorState} from "../state/ui/CommunicatorState";
import {AppConfig} from "../AppConfig";
import {UserFullState} from "../state/cache/UserFullState";
import {ScrollService} from "../service/ScrollService";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface UserCardProps {
    currentUserState: UserFullState;
    setCurrentUserState: (currentUserState: UserFullState) => void;
}

export function UserCard(props: UserCardProps) {
    const history = useHistory();
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    const logoutHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const userService = new UserService();
        event.preventDefault();

        // Backend logout
        userService.logout()
            .then(() => {
                // Frontend logout
                props.setCurrentUserState(new UserFullState());

                // Navigate to home page
                history.push('/');
                ScrollService.scrollToTop();
            })
            .catch((error: Error) => {
                setCommunicatorState(new CommunicatorState('Error logging out: ' + error.message, 'danger', true));
            });
    };

    return (
        <div className="card mb-3">
            <h6 className="card-header">User Management</h6>
            <div className="card-body text-center">
                <div className="card-text">
                    {props.currentUserState.userFull ?
                        <>
                            {
                                props.currentUserState.userFull.avatarFilename &&
                                <div className="mb-2">
                                    <Link to={'/user/' + props.currentUserState.userFull.id}>
                                        <img src={'/' + AppConfig.AvatarImagesPath + '/' + props.currentUserState.userFull.avatarFilename} alt="" className="avatar"/>
                                    </Link>
                                </div>
                            }
                            <Link to={'/user/' + props.currentUserState.userFull.id}>{props.currentUserState.userFull.name}</Link> (<a href="/" onClick={logoutHandler}>Logout</a>)
                        </>
                        : <>
                            Guest (<Link to="/login">Login</Link> or <Link to="/register">Register</Link>)
                        </>
                    }
                </div>
            </div>
        </div>
    );
}