import {EntityMetadata} from "../EntityMetadata";
import {UserFull} from "../user/UserFull";
import {BaseEntity} from "../BaseEntity";
import {EntitySchema} from "../../component/schema/EntitySchema";
import {FieldDefinition} from "../../component/schema/FieldDefinition";
import {FieldType} from "../../component/schema/FieldType";

export class MessageFull implements BaseEntity {
    id: number | null = null;
    _metadata: EntityMetadata = new EntityMetadata();
    user: UserFull | null = null;
    name: string = '';
    email: string = '';
    subject: string = '';
    message: string = '';
    read: boolean = false;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    static readonly AdminSchema: EntitySchema = new EntitySchema(
        'message',
        'messages',
        [
            new FieldDefinition('id', 'ID', FieldType.Link),
            new FieldDefinition('user', 'User', FieldType.User),
            new FieldDefinition('name', 'Name', FieldType.String),
            new FieldDefinition('subject', 'Subject', FieldType.String),
            new FieldDefinition('message', 'Message', FieldType.String, 40),
            new FieldDefinition('createdAt', 'Created', FieldType.Date),
        ]
    );
}