import {CompanySummary} from "../../model/company/CompanySummary";
import {CacheState} from "./CacheState";

export class CompanySummaryState implements CacheState {
    constructor(public airlineCompanySummaries: CompanySummary[] = [],
                public hotelCompanySummaries: CompanySummary[] = [],
                public autoCompanySummaries: CompanySummary[] = [],
                public airlineFirstLetterToCompanySummaryMap = new Map<string, CompanySummary[]>(),
                public hotelFirstLetterToCompanySummaryMap = new Map<string, CompanySummary[]>(),
                public airlineCompanySummariesByMatchRate: CompanySummary[] = [],
                public hotelCompanySummariesByMatchRate: CompanySummary[] = [],
                public pendingRequest: boolean = false,
                public loaded: boolean = false) {}
}