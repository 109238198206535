import {FieldConfig, useField} from "formik";

export interface FormInputProps {
    label: string;
    formText?: string;
    type?: string;
    readOnly?: boolean;
}

export function FormInput({label, formText, ...props}: FormInputProps & JSX.IntrinsicElements['input'] & FieldConfig<string>) {
    const [fieldInputProps, fieldMetaProps] = useField<string>(props);
    return (
        <div>
            <label htmlFor={props.id || props.name} className="form-label">{label}</label>
            <input type={props.type}
                   className={'form-control' + ((fieldMetaProps.touched && fieldMetaProps.error) ? ' is-invalid' : '')}
                   readOnly={props.readOnly}
                   {...fieldInputProps}/>
            {fieldMetaProps.touched && fieldMetaProps.error
                ? <div className="invalid-feedback">{fieldMetaProps.error}</div>
                : null}
            {formText
                ? <div className="form-text">{formText}</div>
                : null}
        </div>
    );
}

FormInput.defaultProps = {
    type: "text"
};