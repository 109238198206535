import {CommentFull} from "../model/comment/CommentFull";
import {StatusmatcherComment} from "./StatusmatcherComment";
import {Comment} from "../model/comment/Comment";
import {useContext} from "react";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface CommentListProps {
    commentFulls: CommentFull[];
    onSubmit: (comment: Comment) => void;
    onDelete: (commentId: number) => void;
}

export function CommentList(props: CommentListProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    // Handle case of no data
    if (!props.commentFulls || props.commentFulls.length === 0)
        return null;

    function renderComments(commentFulls: CommentFull[]) {
        return commentFulls.map(commentFull => <StatusmatcherComment
            commentFull={commentFull}
            setCommunicatorState={setCommunicatorState}
            onSubmit={props.onSubmit}
            onDelete={props.onDelete}
            key={commentFull.id}
        />);
    }

    return <div>
        <h4>Comments</h4>
        {renderComments(props.commentFulls)}
    </div>
}