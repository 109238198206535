import {default as React, useEffect} from "react";
import {CompanySummaryState} from "../state/cache/CompanySummaryState";
import {CompanyService} from "../service/entity/CompanyService";

export interface AutoPageProps {
    companySummaryState: CompanySummaryState;
}

export function AutoPage(props: AutoPageProps) {
    useEffect(() => {
        document.title = 'Browse Auto Rental Firms | StatusMatcher';
    });

    const companyService = new CompanyService();

    return (
        <div>
            <h3>Browse Auto Rental Firms</h3>
            <hr/>
            <div className="row">
                <div className="col">
                    {companyService.renderCompanySummaryLinkArray(props.companySummaryState.autoCompanySummaries)}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <span className="badge rounded-pill">M</span> <i>indicates likely to match</i><br/>
                    <span className="badge rounded-pill">C</span> <i>indicates likely to offer a challenge</i>
                </div>
            </div>
        </div>
    );
}