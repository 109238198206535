import {CommunicatorState} from "../state/ui/CommunicatorState";
import {useHistory, useLocation, useParams} from "react-router";
import {Company} from "../model/company/Company";
import {EntityState} from "../state/cache/EntityState";
import {SyntheticEvent, useContext, useEffect, useState} from "react";
import {CompanyStateService} from "../service/state/cache/CompanyStateService";
import {FetchErrorService} from "../service/error/FetchErrorService";
import {ProgramFull} from "../model/program/ProgramFull";
import {NumberService} from "../service/NumberService";
import {Bar} from "react-chartjs-2";
import {ColorService} from "../service/ColorService";
import {StatusService} from "../service/entity/StatusService";
import {ProgramReportListState} from "../state/cache/ProgramReportListState";
import {ProgramReportListStateService} from "../service/state/cache/ProgramReportListStateService";
import {ListView} from "../component/ListView";
import {ReportSummary} from "../model/report/ReportSummary";
import {ReportListItem} from "../businesscomponent/ReportListItem";
import {PaginationService} from "../service/PaginationService";
import {ScrollService} from "../service/ScrollService";
import {ProgramAndStatusesState} from "../state/cache/ProgramAndStatusesState";
import {ReportForm} from "../businesscomponent/ReportForm";
import {Report} from "../model/report/Report";
import {UserFullState} from "../state/cache/UserFullState";
import {ReportFull} from "../model/report/ReportFull";
import {EntityStateService} from "../service/state/cache/EntityStateService";
import {SetCommunicatorStateContext} from "../context/SetCommunicatorStateContext";

export interface CompanyPageProps {
    programAndStatusesState: ProgramAndStatusesState;
    currentUserState: UserFullState;
}

interface CompanyPageRouteParams {
    alias: string;
}

export function CompanyPage(props: CompanyPageProps) {
    const setCommunicatorState = useContext(SetCommunicatorStateContext);
    const location = useLocation();
    const history = useHistory();
    const companyPageRouteParams = useParams<CompanyPageRouteParams>();
    const [companyState, setCompanyState] = useState(new EntityState<Company>());
    const [programFullState, setProgramFullState] = useState(new EntityState<ProgramFull>());
    const [toProgramReportListState, setToProgramReportListState] = useState(new ProgramReportListState());
    const [fromProgramReportListState, setFromProgramReportListState] = useState(new ProgramReportListState());

    useEffect(() => {
        if (companyState.entity) {
            document.title = companyState.entity.name + ' Status Match | StatusMatcher';
        } else {
            document.title = 'Company | StatusMatcher';
        }
    });

    // Fetch company
    useEffect(() => {
        if (companyPageRouteParams.alias) {
            (new CompanyStateService()).refresh({alias: companyPageRouteParams.alias}, companyState, setCompanyState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'company');
                });
        }
    }, [companyPageRouteParams.alias, companyState, setCommunicatorState]);

    // Fetch program
    useEffect(() => {
        if (companyState.entity && companyState.entity.programId) {
            const entityStateService = new EntityStateService<ProgramFull>('program', 'full');
            entityStateService.refresh({id: companyState.entity.programId}, programFullState, setProgramFullState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'program');
                });
        }
    }, [companyState.entity, programFullState, setCommunicatorState]);

    // Fetch "to" report list
    useEffect(() => {
        if (companyState.entity && companyState.entity.programId) {
            (new ProgramReportListStateService()).refresh({
                    programId: companyState.entity.programId,
                    to: true,
                    page: PaginationService.getPageFromLocation(location, 'page-to')
                },
                toProgramReportListState,
                setToProgramReportListState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'report');
                });
        }
    }, [companyState.entity, toProgramReportListState, setCommunicatorState, location]);

    // Fetch "from" report list
    useEffect(() => {
        if (companyState.entity && companyState.entity.programId) {
            (new ProgramReportListStateService()).refresh({
                    programId: companyState.entity.programId,
                    to: false,
                    page: PaginationService.getPageFromLocation(location, 'page-from')
                },
                fromProgramReportListState,
                setFromProgramReportListState)
                .catch((error: Error) => {
                    (new FetchErrorService()).handlePageLoadError(error, setCommunicatorState, 'report');
                });
        }
    }, [companyState.entity, fromProgramReportListState, setCommunicatorState, location]);

    if (!companyState.entity) return null;
    const company: Company = companyState.entity;

    if (!programFullState.entity) return null;
    const programFull: ProgramFull = programFullState.entity;

    // Create template report with some initial values
    const reportFull: ReportFull = new ReportFull();
    reportFull.fromProgram = programFull;
    reportFull.toProgram = programFull;

    function getReportSummaryText(programFull: ProgramFull) {
        if (programFull.reportCount === 0) {
            return 'No reports have been submitted yet for obtaining status with ' + programFull.name;
        }

        if (programFull.matchReportCount >= programFull.denyReportCount &&
            programFull.matchReportCount >= programFull.challengeReportCount &&
            programFull.matchReportCount >= programFull.otherReportCount) {
            return <>
                {programFull.name} is most likely to <strong>Match</strong> Status based
                on {programFull.matchReportCount} of {programFull.reportCount} submitted reports
                ({NumberService.formatFractionAsPercentageString(programFull.matchReportCount, programFull.reportCount)})
            </>
        }

        if (programFull.challengeReportCount >= programFull.matchReportCount &&
            programFull.challengeReportCount >= programFull.denyReportCount &&
            programFull.challengeReportCount >= programFull.otherReportCount) {
            return <>
                {programFull.name} is most likely to offer a <strong>Challenge</strong> based
                on {programFull.challengeReportCount} of {programFull.reportCount} submitted reports
                ({NumberService.formatFractionAsPercentageString(programFull.challengeReportCount, programFull.reportCount)})
            </>
        }

        if (programFull.denyReportCount >= programFull.matchReportCount &&
            programFull.denyReportCount >= programFull.challengeReportCount &&
            programFull.denyReportCount >= programFull.otherReportCount) {
            return <>
                {programFull.name} is most likely to <strong>Deny</strong> a Status Match based
                on {programFull.denyReportCount} of {programFull.reportCount} submitted reports
                ({NumberService.formatFractionAsPercentageString(programFull.denyReportCount, programFull.reportCount)})
            </>
        }

        return <>{programFull.name} is unlikely to offer a Status Match based on the {programFull.reportCount} reports
            submitted</>
    }

    function renderBarChart(programFull: ProgramFull) {
        const data = {
            labels: ['Match', 'Challenge', 'Deny', 'Other'],
            datasets: [
                {
                    data: [
                        programFull.matchReportCount,
                        programFull.challengeReportCount,
                        programFull.denyReportCount,
                        programFull.otherReportCount
                    ],
                    backgroundColor: [
                        ColorService.StatusmatcherColorLight,
                        ColorService.StatusmatcherColorLight,
                        ColorService.StatusmatcherGrey,
                        ColorService.StatusmatcherGrey
                    ],
                    hoverBackgroundColor: [
                        ColorService.StatusmatcherColorLight,
                        ColorService.StatusmatcherColorLight,
                        ColorService.StatusmatcherGrey,
                        ColorService.StatusmatcherGrey
                    ]
                },
            ],
        };

        const options = {
            animation: false as false,
            indexAxis: 'y' as 'y',
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 16,
                            weight: '400'
                        },
                        color: ColorService.StatusmatcherTextColor
                    },
                    grid: {
                        display: false
                    },
                    suggestedMax: 6
                },
                y: {
                    ticks: {
                        font: {
                            size: 16,
                            weight: '400'
                        },
                        color: ColorService.StatusmatcherTextColor
                    },
                    grid: {
                        display: false
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            },
        };

        return <Bar data={data} options={options} className="pe-5"/>
    }

    function renderSummarySection(programFull: ProgramFull) {
        return <>
            <h4>Summary of Past Requests</h4>
            <p>{getReportSummaryText(programFull)}</p>
            {
                programFull.reportCount > 0 && renderBarChart(programFull)
            }
        </>
    }

    function onNewReportClick(e: SyntheticEvent) {
        e.preventDefault();
        ScrollService.scrollToElement('new-report');
    }

    function renderRequestSection(company: Company, programFull: ProgramFull) {
        const statusService = new StatusService();
        return <div className="text-break">
            <h4>Request a Status Match</h4>
            {/* eslint-disable-next-line */}
            <p>Sign up for the <a href={programFull.url} target="_blank">{programFull.name}</a> program</p>
            {
                programFull.email &&
                <p>Contact by email: <a href={'mailto:' + programFull.email}>{programFull.email}</a></p>
            }
            {/* eslint-disable-next-line */}
            {programFull.webForm && <p>Contact by web form: <a href={programFull.webForm} target="_blank">{programFull.webForm}</a></p>}
            <p className="mb-2">Ask to be granted one of the status levels:</p>
            <div className="mb-1">
                {programFull.statuses.map(s => statusService.renderStatus(s, false, true))}
            </div>
            <p>Make a booking with {company.name}</p>
            <p>Share your results in a <a href="#newReport" onClick={onNewReportClick}>New Report</a></p>
        </div>
    }

    function renderReportListSection(programReportListState: ProgramReportListState, programFull: ProgramFull) {
        const elementId = 'report-list-' + (programReportListState.to ? 'to' : 'from');
        const pageParam = 'page-' + (programReportListState.to ? 'to' : 'from');
        const currentPage = programReportListState.pageMetadata ? programReportListState.pageMetadata.number : 0;

        return <>
            <h4 id={elementId} className="mb-3">
                {programReportListState.to ?
                    'Reports for obtaining status with '
                    : 'Reports for leveraging status with '
                }
                {programFull.name}
            </h4>
            {
                programReportListState.reportSummaries.length > 0 ?
                    <ListView<ReportSummary>
                        listItems={programReportListState.reportSummaries}
                        pageMetadata={programReportListState.pageMetadata}
                        renderListItem={ReportListItem}
                        pluralListItemName="reports"
                        showSummary={false}
                        onPreviousPage={() => {
                            PaginationService.handlePreviousPage(location, history, currentPage, pageParam);
                            ScrollService.scrollToElement(elementId);
                        }}
                        onNextPage={() => {
                            PaginationService.handleNextPage(location, history, currentPage, pageParam);
                            ScrollService.scrollToElement(elementId);
                        }}
                    />
                    : 'No reports'
            }
        </>;
    }

    function onReportSubmit(report: Report) {
        history.push('/');
        setCommunicatorState(new CommunicatorState('Thank you for submitting a new Status Match report! Your report will be reviewed by the site moderator and integrated with the site content shortly.',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
    }

    function renderReportFormSection(reportFull: ReportFull) {
        return <>
            <h4 id="new-report">Add New Status Match Report</h4>
            <ReportForm
                edit={false}
                reportFull={reportFull}
                programAndStatusesMap={props.programAndStatusesState.programAndStatusesMap}
                onSubmit={onReportSubmit}
                updateCommunicatorState={setCommunicatorState}
                currentUserFull={props.currentUserState.userFull}
            />
        </>;
    }

    return <div>
        <h3>{company.name} Status Match</h3>
        <hr/>
        <div className="row">
            <div className="col-lg-6">
                {renderSummarySection(programFull)}
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
                {renderRequestSection(company, programFull)}
            </div>
        </div>
        <hr/>
        {renderReportListSection(toProgramReportListState, programFull)}
        <hr/>
        {renderReportFormSection(reportFull)}
        <hr/>
        {renderReportListSection(fromProgramReportListState, programFull)}
    </div>
}