import {ReportForm} from "../../businesscomponent/ReportForm";
import {Report} from "../../model/report/Report";
import {CommunicatorState} from "../../state/ui/CommunicatorState";
import {ProgramAndStatusesState} from "../../state/cache/ProgramAndStatusesState";
import {useContext, useEffect} from "react";
import {UserFullState} from "../../state/cache/UserFullState";
import {ScrollService} from "../../service/ScrollService";
import {SetCommunicatorStateContext} from "../../context/SetCommunicatorStateContext";
import {useHistory} from "react-router";

export interface ReportCreatePageProps {
    programAndStatusesState: ProgramAndStatusesState;
    currentUserState: UserFullState;
}

export function ReportCreatePage(props: ReportCreatePageProps) {
    const history = useHistory();
    const setCommunicatorState = useContext(SetCommunicatorStateContext);

    useEffect(() => {
        document.title = 'Create New Status Match Report | StatusMatcher';
    });

    function onSubmit(report: Report) {
        history.push('/');
        setCommunicatorState(new CommunicatorState('Thank you for submitting a new Status Match report! Your report will be reviewed by the site moderator and integrated with the site content shortly.',
            'success',
            true,
            true));
        ScrollService.scrollToTop();
    }

    return (
        <div>
            <h3>Create New Status Match Report</h3>
            <hr/>
            <p>
                Have you attempted to obtain a status level with one loyalty program by leveraging another? If so, help
                your fellow travelers by providing the details here.
            </p>
            <ReportForm
                edit={false}
                programAndStatusesMap={props.programAndStatusesState.programAndStatusesMap}
                onSubmit={onSubmit}
                updateCommunicatorState={setCommunicatorState}
                currentUserFull={props.currentUserState.userFull}
            />
        </div>
    );
}